import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API,{base} from "../api.js";
import { Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Refund extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false,
            list:[]
        }
    }

    componentDidMount() {
      // ReactGA.initialize('UA-157984080-1');
      // ReactGA.pageview(window.location.pathname + window.location.search);
      ReactPixel.init('3847665161963731');
      ReactPixel.pageView();
        API({
              method: 'GET',
              url: "display/video",
              data:'json',
            }).then(data => {
                console.log(data.data.response.data)
              this.setState({list:data.data.response.data});
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
    }
 render(){
    return (
    <React.Fragment>
    <Header />
    <div class="innerPage">
      <section class="bannerOuter">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h1>Wall of Fame</h1>
              <span>Meet our champions who have made it in the PTE / NAATI CCL exam</span><br />
              <a href="javascript:;" class="downArrow">
                <img src="images/banner-arrow.png" alt="banner-arrow" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
      <section className="fameWall" style={{marginTop:'35px'}}>
          <div className="container">
              <div className="row">
                  <div className="col-sm-12 text-center">
                      <div class="row">
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte1.jpg" alt="fame-img-01" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte2.jpg" alt="fame-img-02" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte3.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte4.jpg" alt="fame-img-04" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte5.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte6.jpg" alt="fame-img-01" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte7.jpg" alt="fame-img-02" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte8.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte9.jpg" alt="fame-img-04" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte10.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte11.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/pte/pte12.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/punjabi3.jpg" alt="fame-img-01" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/urdu1.jpg" alt="fame-img-02" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi3.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi1.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi4.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi5.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi6.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi7.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi8.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi9.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi10.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi11.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/nepali4.jpg" alt="fame-img-04" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/bangla1.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/nepali6.jpg" alt="fame-img-01" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/urdu2.jpg" alt="fame-img-02" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/urdu3.jpg" alt="fame-img-03" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/hindi2.jpg" alt="fame-img-04" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/nepali2.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/nepali3.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/nepali4.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/nepali5.jpg" alt="fame-img-05" />
                        </div>
                        <div class="col-4 fameCard">
                          <img src="images/naati/nepali6.jpg" alt="fame-img-05" />
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    <Footer />
  </React.Fragment>);
  }
}

export default Refund;
