import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import CoursesWeOffer from '../components/Frontend/coursesWeOffer';
import FameWall from '../components/Frontend/FameWall';
import Testimonial from '../components/Frontend/Testimonial';
import VideoTuts from '../components/Frontend/VideoTuts';
import WhyLove from '../components/Frontend/WhyLove';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import API, {base} from "api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import {
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indexModal:false,
            name:"",
            phone:"",
            email:"",
            center:"",
            process:false
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        if((store.getState().token)?false:true){
            setTimeout(() => { this.setState({indexModal:true}) }, 1000);
        }
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
        this.timerFunc()
    }

    timerFunc(){
       /* var countDownDate = new Date("Apr 4, 2021 16:37:52").getTime();

        // Run myfunc every second
        var myfunc = setInterval(function() {

        var now = new Date().getTime();
        var timeleft = countDownDate - now;

        // Calculating the days, hours, minutes and seconds left
        var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        // Result is output to the specific element
        //document.getElementById("days").innerHTML = days + "d "
        //document.getElementById("hours").innerHTML = hours + "h "
        //document.getElementById("mins").innerHTML = minutes + "m "
        //document.getElementById("secs").innerHTML = seconds + "s "
        if (document.querySelectorAll('#timer-id').length > 0) {
            document.getElementById("timer-id").innerHTML= days+' Days, '+hours+':'+minutes+':'+seconds+' Left';
        }*/


        // Display the message when countdown is over
        /*if (timeleft < 0) {
            clearInterval(myfunc);
        }
        });*/
    }

    closeIndexModal = (e) => {
    	e.preventDefault();
        this.setState({indexModal:false});
    }

    changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    postSubmit = async(e) => {

        if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return false;
        }
        e.preventDefault();
        this.setState({process:true});
        var bodyFormData = new FormData();
        bodyFormData.append('phone', this.state.phone);
        bodyFormData.append('name', this.state.name);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('center', this.state.center);
        let res = null;
        try {
			res = await API({
				method: 'POST',
				url: '/submit-query',
				data: bodyFormData
			});
        } catch(error) {
            this.setState({process:false });
            return "l";
        };

        if (res.status === 200) {
          store.dispatch(setAlert({ 'flag': true, 'type': 1, 'message': res.data.response.message, process: false }));
          this.setState({ indexModal: false });
        } else if (res.response.status === 422) {
          this.setState({ error: res.response, process: false });
          let str = '';
          Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          this.setState({ error_bag: str });
        }
	}

	render() {
		return (
    		<React.Fragment>
    			<Helmet htmlAttributes>
        			<title>Best PTE, NAATI & IELTS Coaching Classes| LA Academy India</title>
				    <meta name="twitter:title" content="Best PTE Coaching Institute in Jalandhar, India - LA- LA-Language Academy" />
				    <meta property="og:title" content="Best PTE Coaching Institute in Jalandhar, India - LA- LA-Language Academy" />
				    <meta name="description" content="Achieve your PTE, NAATI and IELTS goals with LA Language Academy's expert coaching and comprehensive study materials. Join our classes and excel in your exams." />

        			<meta name="twitter:description" content="The LA- LA-Language Academy is one of the top institutes from Australia now emerging in India. We offer aspirants a superb educational environment in Jalandhar, India. Join for the free PTE/IELTS trial now and kickstart your PTE/IELTS journey now." />
        			<meta property="og:description" content="The LA- LA-Language Academy is one of the top institutes from Australia now emerging in India. We offer aspirants a superb educational environment in Jalandhar, India. Join for the free PTE/IELTS trial now and kickstart your PTE/IELTS journey now." />
      			</Helmet>

				<Modal isOpen={this.state.indexModal} size="lg" id="anouncementPopup" >
    				<div className="modal-content">
      					<div className="modal-header" hidden="" >
        					<a onClick={(e)=>{this.closeIndexModal(e)}} style={{color: '#93c13e',opacity: 'unset'}} href="#" className="close" data-dismiss="modal" aria-label="Close">
          						<span aria-hidden="true">×</span>
        					</a>
      					</div>
      					<div className="modal-body pte_popup_modal_body">
        					<div className="row">
            					<div className="col-md-6 col-sm-6 col-xs-12">
            						<img className="pte-popup-logo-img" style={{width: '100px'}} src="/images/logol.png" />
                					<p className="pte_popup_modal_ptag"></p>
              					<div>
              					<h2 className="pte_popup_modal_h2tag pte_popup_modal_h2tagl1" style={{textAlign: 'center', fontSize:'23px', margin: '30px 0px 0px 0px', padding: '0px',fontWeight:'700'}}>Attend a </h2>
                				<h2 className="pte_popup_modal_h2tag pte_popup_modal_h2tagl2" style={{fontSize: '27px',fontStyle: 'italic',margin:'5px 0px',textAlign: 'center'}}><b style={{color:'#a7ff00'}}>FREE TRIAL CLASS</b> </h2>
                				<h2 className="pte_popup_modal_h2tag pte_popup_modal_h2tagl3" style={{textAlign: 'center', fontSize: '23px', margin: '0px', padding: '0px',color: 'black'}}>for PTE, NAATI CCL or IELTS with us.</h2>
              					<p className="pte_popup_modal_ptag" style={{ color: 'red',fontWeight: '700'}} id="timer-id"> </p>
              				</div>
            			</div>
            			<div className="col-md-6 col-sm-6 col-xs-12 pte_popup_modal_seccol">
                			<div className="pte_popup_modal_sec_inner_col">
                				<h4 className="pte_popup_modal_h4tag" style={{textAlign: 'center'}}>Book NOW!</h4>
                				<p className="pte_popup_modal_ptag" style={{textAlign: 'center'}}>Submit Your Details Below</p>
                				<p className="pte_popup_modal_ptag" style={{textAlign: 'center'}}><i class="fas fa-long-arrow-alt-down"></i></p>
                				<div className="pte-popup-form-start">
                  					<form onSubmit={this.postSubmit}>
                    					<div className="col-md-12">
                      						<input className="ptepop_name form-control" onChange={(e) => { this.changeInput(e)} } value={this.state.name} name="name" placeholder="Name*" type="text" />
                        					{this.validator.message('name', this.state.name, 'required')}
                      						<span className="err name-error"></span>
                    					</div>
                    					<div className="col-md-12">
                      						<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
					                      	<input
												className="ptepop_phone form-control"
												onChange={(e) => { this.changeInput(e)} }
												value={this.state.phone}
												name="phone"
												placeholder="Phone Number*"
												type="text"
					                      	/>
                    						{this.validator.message('phone', this.state.phone, 'required|numeric|phone')}
                  							<span className="err phone-error"></span>
                						</div>
                						<div className="col-md-12">
                  							<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
											<input
												className="form-control ptepop_email"
												name="email"
												onChange={(e) => { this.changeInput(e)} }
												value={this.state.email}
												placeholder="Email Address*"
												type="email"
					                        />
                							{this.validator.message('email', this.state.email, 'required|email')}
              								<span className="err email-error"></span>
            							</div>
            							<div className="col-md-12">
              								<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
											<select
												class="form-control ptepop_email"
												name="center"
												onChange={(e) => { this.changeInput(e)} }
												value={this.state.center}
											>
						                        <option value="">Select center</option>
						                        <option>Parramatta</option>
						                        <option>Sydney CBD</option>
						                        <option>Jalandhar</option>
						                        <option>Online</option>
											</select>
            								{this.validator.message('center', this.state.center, 'required')}
          									<span className="err email-error"></span>
        								</div>
										<div className="col-md-12 row_before_pte_submit">
											<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
											{!this.state.process && <a onClick={this.postSubmit} className="btn btn-primary pte_popup_modal_submit">Submit</a>}
											{this.state.process && <a className="btn btn-primary pte_popup_modal_submit">Wait...</a>}
										</div>
									</form>
                				</div>
				                <div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
				                <div className="col-md-12" hidden=""><p className="pte_popup_modal_ptag" style={{textAlign: 'center'}}><a style={{fontSize: '13px', color: 'white', textDecoration: 'underline'}} href="#" className="modal-close" data-dismiss="modal">No, I'm not interested in Six Sigma courses</a></p></div>
              				</div>
            			</div>
        			</div>
      			</div>
    				</div>
				</Modal>
		    	<div className="homePage">
		    		<Header />
		    	</div>
				<section className="homeBanner">
		    		<div className="container">
		        		<div className="row">
		            		<div className="col-sm-6 my-auto">
				                <h1>Enrol at the <span>Top NAATI & PTE Training Institute</span>  in India and nail the exam like a pro</h1>
				                <h2>Attend a free trial class at our PTE / NAATI CCL coaching<br /> centre in Jalandhar, India and feel the difference</h2>
				                <a href={`${process.env.REACT_APP_LIVE_URL}/auth/register`} className="themeBtn">Register</a>
				                <Link to="/contact" className="themeBtn blueBtn">Contact us</Link>
		            		</div>
				            <div className="col-sm-6">
				                <img src="images/bannerImg.png" alt="bannerImg" />
				            </div>
		        		</div>
		    		</div>
				</section>
				<CoursesWeOffer class="courses" />
				<FameWall />
				<section className="scoredArea">
		    		<div className="container">
		        		<div className="row speakingScore">
		            		<div className="col-sm-6">
		                		<img src="images/speaking-score.png" alt="speaking-score" />
		            		</div>
		            		<div className="col-sm-6 scoreInfo my-auto">
		                		<img src="images/thums-up.png" alt="thums-up" />
		                		<h3>Scored Speaking<br /> in real time</h3>
		                		<p>With feedback on all the tasks in speaking section, you can instantly analyse fluency, pronunciation and content.</p>
		                		<Link to="/contact" class="themeBtn">Contact us</Link>
		            		</div>
		        		</div>
		        		<div className="row scorecardReport">
		            	<div className="col-sm-6 scoreInfo my-auto">
			                <img src="images/fingers-up.png" alt="fingers-up" />
			                <h3>Instant Scorecard Report for scored sectional and full mock test</h3>
			                <p> With the smartest AI checker, you will receive instant scorecard including in-depth feedback and analysis. You can easily track your mistakes, score contribution and make the necessary changes.</p>
			                <Link to="/contact" class="themeBtn">Contact us</Link>
		            	</div>
			            <div className="col-sm-6">
			                <img src="images/scored-report.png" alt="scored-report" />
			            </div>
		        	</div>
		        	<div className="row repeatedQues">
			            <div className="col-sm-6">
			                <img src="images/repeated-questions.png" alt="repeated-questions" />
			            </div>
			            <div className="col-sm-6 scoreInfo my-auto">
			                <img src="images/questionMark.png" alt="questionMark" />
			                <h3>New Repeated Questions and Predictions </h3>
			                <p>Getting access to the PTE weekly predictions with highly repeated questions will make your journey even smoother.</p>
			                <Link to="/contact" class="themeBtn">Contact us</Link>
			            </div>
		        	</div>
		    	</div>
				</section>
				{/*<section class="pteNatti">
				    <div class="container">
				        <div class="row text-center">
				            <div class="col-sm-12">
				                <h2 class="title">Title</h2>
				            </div>
				            <div class="col-sm-6 infoBox">
				                <div class="infoBoxInr">
				                    <h3>PTE</h3>
				                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
				                </div>
				            </div>
				            <div class="col-sm-6 infoBox">
				                <div class="infoBoxInr">
				                    <h3>Nati</h3>
				                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
				                </div>
				            </div>
				        </div>
				    </div>
				</section>*/}
			    <VideoTuts />
			    <WhyLove />
			    <Testimonial />
			    <Footer />
    		</React.Fragment>
		);
    }
}

export default Home;
