import React from 'react';
import Header from '../../components/pteportal/Header';
import Footer from '../../components/pteportal/Footer';
import ClientFeedback from '../../components/pteportal/ClientFeedback';

import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import AOS from 'aos';
import ReactPixel from 'react-facebook-pixel';
import API, {base} from "api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import {
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
class About extends React.Component {

    constructor(props) {
        AOS.init();
        super(props);
        this.state = {
            indexModal:false,
            name:"",
            phone:"",
            email:"",
            center:"",
            process:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        if((store.getState().token)?false:true){
            setTimeout(() => { this.setState({indexModal:true}) }, 10000);
        }
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
    }

    closeIndexModal = () =>{
        this.setState({indexModal:false});
    }

    changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    postSubmit = async(e) => {

        if (!this.validator.allValid()) {
          this.validator.showMessages();
          this.forceUpdate();
          return false;
        }
        e.preventDefault();
        this.setState({process:true});
        var bodyFormData = new FormData();
        bodyFormData.append('phone', this.state.phone);
        bodyFormData.append('name', this.state.name);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('center', this.state.center);
        let res = null;
        try{
          res = await API({
            method: 'POST',
            url: '/submit-query',
            data: bodyFormData
          })
        }catch(error) {
            this.setState({process:false });
            return "l";
        };
        console.log(res);
        if (res.status === 200) {
          store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message,process:false}));
          this.setState({indexModal:false});
        }else if (res.response.status === 422) {
          this.setState({error:res.response,process:false });
          let str = '';
          Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          this.setState({error_bag:str});
        }


  }
 render(){
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney</title>
        <meta name="twitter:title" content="Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney" />
        <meta property="og:title" content="Top PTE Training Institute In Parramatta | Best NAATI CCL Coaching Centre In Sydney" />
        <meta name="description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />

        <meta name="twitter:description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />
        <meta property="og:description" content="Language Academy, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@languageacademy.com.au" />
      </Helmet>

  <section class="header-with-banner">
    <Header active="about" />
    <section id="hero about-us-page" class="d-flex align-items-center">
      <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-12 mb-5">
            <h2 class="main-heading pt-5 text-center">About Pteportal</h2>
            <div class="breadcum">
              <ol class="breadcrumb primary-color text-center">
                  <li class="breadcrumb-item"><a class="white-text" href="#">Home</a></li>
                  <li class="breadcrumb-item active">About</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  <main id="main" class="avout-page">


    <section id="team" class="team section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Know About The Pteportal</h2>
          <p>Pteportal provides the PTE aspirants the opportunity to get minute details about the PTE test before the exam day. Practicing with pte full mock test with answers are more of the process of tuning oneself before the PTE academic exam. PTE practice test helps to rate your performance and get the scorecard instantly. PTE test consists of four sections having individual question type covering in it. All the exam stimulation is added in our online PTE mock test and PTE practice test to give you an edge while preparing for the PTE test.</p>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/battery.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Instand Result</h4>
              </div>
              <div class="pehraraph">
                <p>Nunde omnier spiciatiss iste natus error sit voluptatem accusantium doloremque laudantium.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/men.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>AI Experts’ advice</h4>
              </div>
              <div class="pehraraph">
                <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/sun.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Accurate Result</h4>
              </div>
              <div class="pehraraph">
                <p>Laudantium. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member-box">
              <div class="member-img">
                <img src="/assets/img/toungh.png" class="img-fluid" alt="" />
              </div>
              <div class="member-heading">
                <h4>Detail Analytics</h4>
              </div>
              <div class="pehraraph">
                <p>Doloremque ut perspiciatis unde omnis iste natus error sit accusantium laudantium voluptatem </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section id="about" class="hero-about">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-12 col-md-12 footer-links">
            <h2 class="banner-heading text-center pb-5">We have successfully produced a large number of students with resounding and outstanding success.</h2>
              <div class="list-of-online">
                <ul class="left-side-list pb-5">
                  <li>
                    <div class="icon-image"><img src="/assets/img/tick.png" class="img-fluid" alt="" /></div>
                    <div class="list-content">
                      PTE Master provides PTE training courses to people of all ages who wish to outshine others by achieving higher scores and help them to fulfill their dreams of making headways and measurable progress in life.
                    </div>
                  </li>
                  <li>
                  <div class="icon-image"><img src="/assets/img/tick.png" class="img-fluid" alt=""/></div>
                    <div class="list-content">
                    We also provide students with automated Mock Test whereby when they take the exam, it will automatically generate scores for them with the accuracy that is 100% equivalent to the real exam.</div>
                  </li>
                  <li>
                    <div class="icon-image"><img src="/assets/img/tick.png" class="img-fluid" alt="" /></div>
                    <div class="list-content">As a result of our industrious and experiences in providing solutions to the PTE exam, we have succeeded in establishing the first center in Australia to have developed a fully computerized platform that permits students to participate in the mock test and receive the result in no time.</div>
                  </li>
                  <li>
                    <div class="icon-image"><img src="/assets/img/tick.png" class="img-fluid" alt="" /></div>
                    <div class="list-content">PTE Master provides a wide range of educational products and services through a holistic approach to individuals, government, and institutions.</div>
                  </li>
                  <li>
                    <div class="icon-image"><img src="/assets/img/tick.png" class="img-fluid" alt="" /></div>
                    <div class="list-content">We are committed to using research methods of approach to understanding the kind of learning that works best, gathering different people andorganizations to develop new ideas and come back round to measure the outcomes of our produced products.</div>
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section id="power-section" class="team section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Take Scored PTE Mock Test <i><b>Free</b></i> Now</h2>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="sign-box">
              <div class="sign-up-free">
                <button type="submit" class="btn btn-submit">Sign Up For Free</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>

  <Footer />

    </React.Fragment>);
    }
}

export default About;
