import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWallPte from '../components/Frontend/FameWallPte';
import Testimonial from '../components/Frontend/Testimonial';
import WhyLove from '../components/Frontend/WhyLove';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
class Pte extends React.Component {
    componentDidMount() {
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
    }
 render(){
    return (
    <React.Fragment>
    <Helmet>
    	<title>Best PTE Online Coaching Classes in India | LA Academy India</title>
    	<meta name="description" content="Enroll in LA Language Academy's PTE online classes for flexible and effective preparation in India. Access expert coaching and resources from anywhere, anytime." />
    </Helmet>
    <Header />
      <div class="innerPage">
    	<section class="bannerOuter">
			<div class="container">
				<div class="row">
					<div class="col-sm-12 text-center">
						<h1> Start your PTE Exam Preparation online with us and Achieve your Desired Score </h1>
						<span>Begin your journey towards a better tomorrow with the best Online PTE preparation Classes</span><br />
						<a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
					</div>
				</div>
			</div>
		</section>
    </div>
		<section class="pteInfo">
			<div class="container">
				<div class="row">
					<div class="col-lg-10 col-lg-offset-1">
                   <ul class="checklist" style={{padding:'0px'}}>
                      <li>Are you working full-time and got time for PTE exam training only on weekends or at night?</li>
                      <li>Having any issues with the hectic travelling for the PTE exam preparation?</li>
                      <li>Looking for the best instructor for your PTE preparation online but cannot meet them due to lack of limited resources?</li>
                      <li>Are you a time-poor person and wants to get the appropriate time for the PTE exam coaching?</li>
                   </ul>
                   <p class="web-content">
                        If you are ticking the status yes for any of these above problems it means you are here for PTE online training.  We are providing the world-class PTE online coaching to the aspirants so they can achieve their dreams to study abroad.  Our PTE online course material is designed in such a way that students can easily learn the concept from the basic level.
                   </p>
                   <p class="web-content">
                        If you want to Enrol yourself in the PTE online classes or wants to ask any query related to PTE exam preparation online then contact us at <a style={{color: 'rgb(81, 203, 206)'}} href="mailto:info@languageacademy.com.au" target="blank">info@languageacademy.com.au</a>
                   </p>
                </div>
				</div>
			</div>
		</section>
		<FameWallPte />
		<Testimonial />
		<WhyLove />
    	<Footer />
    </React.Fragment>);
    }
}

export default Pte;
