import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API from "../api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Redirect} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            email:"",
            phone:"",
            message:"",
            error:[],
            submit:false,
            flag:false,
            lockSubmit:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]:value});
    }

    submitForm = () =>{
        if (this.validator.allValid() && !this.state.lockSubmit) {
            this.setState({lockSubmit:true});
            let json = {
                "name": this.state.name,
                "email": this.state.email,
                "phone": this.state.phone,
                "message": this.state.message,
            }
            API({
              method: 'POST',
              url: "post/contact",
              data:json,
            }).then(data => {
              this.setState({submit:true,lockSubmit:false,name:'',email:'',phone:'',message:'',flag:true});

              store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.response.message}));
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
 render(){
 	if (this.state.flag) {
 		return (<Redirect to="/thank-you" />);
 	}
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Contact LA Language Academy in India </title>
        <meta name="description" content="Reach out to LA Language Academy India for any inquiries or support. Our team is ready to assist you with your PTE and IELTS preparation needs." />
        <meta name="twitter:title" content="Language Academy" />
        <meta property="og:title" content="Language Academy" />
     </Helmet>
    <Header />
    	<div class="innerPage">
        <section class="bannerOuter">
			<div class="container">
				<div class="row">
					<div class="col-sm-12 text-center">
						<h1>Don’t be Stranger</h1>
						<span>Feel free to contact us if you need some help, consultation or<br /> you have some other questions.</span>
					</div>
				</div>
			</div>
		</section>
		</div>
		<section class="contentOuter">
			<div class="container">
				<div class="row">
					<div class="col-sm-7 loginForm">
							<div class="fieldOuter">
								<input type="text" name="name" onChange={this.handleChange} value={this.state.name} placeholder="Your name..." />
								{this.validator.message('Name', this.state.name, 'required')}
							</div>
							<div class="fieldOuter">
								<input type="email" onChange={this.handleChange} value={this.state.email} name="email" placeholder="Your email..." />
								{this.validator.message('Email', this.state.email, 'required|email')}
							</div>
							<div class="fieldOuter">
								<input type="number" name="phone" onChange={this.handleChange} value={this.state.phone} placeholder="Your phone number..." />
								{this.validator.message('Phone number', this.state.phone, 'required|number')}
							</div>
							<div class="fieldOuter">
								<textarea onChange={this.handleChange} name="message" value={this.state.message} placeholder="Message..."></textarea>
								{this.validator.message('message', this.state.message, 'required')}
							</div>
							<div class="fieldOuter text-right">
								{!this.state.lockSubmit && <button type="submit" name="" onClick={this.submitForm} class="themeBtn" >
								Submit
																</button>}
								{this.state.lockSubmit && <button type="submit" name="" class="themeBtn" >
																	Processing...
																</button>}
							</div>
					</div>
					<div class="col-sm-5 addressArea">
						<h3>Ask Us Anything</h3>
						<ul>
							<li>
								<i class="fa fa-map-marker" aria-hidden="true"></i>
								<span><a style={{color:'white'}} href="https://g.page/lajalandhar?share" target="_blank">172, Green Park, Bedi Paradise Building, Garha Road, opposite Hotel Residency, near Bus Stand, Jalandhar.</a></span>
							</li>
							<li>
								<i class="fa fa-envelope-o" aria-hidden="true"></i>
								<span><a style={{color:'white'}} href="mailto:info@languageacademy.com.au">info@languageacademy.com.au</a></span>
							</li>
							<li>
								<i class="fa fa-mobile" aria-hidden="true"></i>
								<span><a style={{color:'white'}} href="tel:7652811911" >+91 7652811911</a></span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
    <Footer />
    </React.Fragment>);
    }
}

export default Contact;
