import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Branches extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submit:false,
            flag:false,
            lockSubmit:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
    }
 render(){
    return (
        <React.Fragment>
        <Helmet htmlAttributes>
            <title>Language Academy</title>
            <meta name="twitter:title" content="Language Academy" />
            <meta property="og:title" content="Language Academy" />
        </Helmet>
        <Header />
        <div className="innerPage">
            <section className="bannerOuter">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h1>Our Branches</h1>
                            <span>Visit us and fulfil your dreams</span><br />
                            <a href="javascript:;" className="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section className="branchesOuter">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center branchesHeading">
                        <h2>Hello. What can we help you with?</h2>
                        <h3>Our Offices</h3>
                    </div>
                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="https://i.natgeofe.com/n/5c32242b-830e-449b-8e27-88f242ebbeb4/sydney-travel_16x9.jpg" />
                                <h3>Sydney</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Sydney</div>
                                <div className="addressInfo"><p>Suite 502, 630 George Street, Sydney, 2000, NSW, Australia</p></div>
                                <div className="contactNo">

                                    <p>+61 455 566 654</p>
                                </div><div className="locationBtn"><a href="https://goo.gl/maps/M6MCA3i4MRi31kLg6" target="_blank" className="themeBtn">Get Directions</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="https://media-exp1.licdn.com/dms/image/C561BAQHD2hTTgbqArQ/company-background_10000/0/1543359763843?e=2159024400&amp;v=beta&amp;t=OX6QPMyudYqydNPmKoOMs4Jk-FtyawgWubWk7hzbOH8" /><h3>Parramatta</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Parramatta</div>
                                <div className="addressInfo"><p>46 George Street, Parramatta, 2150, NSW, Australia</p></div>
                                <div className="contactNo">

                                    <p>+61 426 230 061</p>
                                </div><div className="locationBtn"><a href="https://goo.gl/maps/fBXRyJt6jdukuzQs8" target="_blank" className="themeBtn">Get Directions</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="https://www.holidify.com/images/bgImages/JALANDHAR.jpg" /><h3>Jalandhar</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Jalandhar</div>
                                <div className="addressInfo"><p>172, Green Park, Bedi Paradise Building, Garha Road, Near Bus Stand, Jalandhar City</p></div>
                                <div className="contactNo">

                                    <p>+91 765 281 1911</p>
                                </div><div className="locationBtn"><a href="https://goo.gl/maps/Exkyq5Uv8iDLFKeC8" target="_blank" className="themeBtn">Get Directions</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="https://images.hindustantimes.com/img/2021/08/10/550x309/b5d6f6f8-fa0a-11eb-ad7c-a6335a5b370f_1628621122330.jpg" /><h3>Gurdaspur</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Gurdaspur</div>
                                <div className="addressInfo"><p>State Bank of India, GT Road, Mandi Branch, Gurdaspur</p></div>
                                <div className="contactNo">

                                    <p>+91 905 651 1913</p>
                                </div><div className="locationBtn"><a href="https://goo.gl/maps/2je7dvg1hLa1jrTy6" target="_blank" className="themeBtn">Get Directions</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="images/amritsar.jpg" /><h3>Amritsar</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Amritsar</div>
                                <div className="addressInfo"><p>D-Block 52-53 SCO, Ranjit Avenue, Opp. Starbucks Building, <br></br>Amritsar</p></div>
                                <div className="contactNo">
                                    <a style={{color:'grey'}} href="tel:919915011914" >+91 991 501 1914</a>
                                </div>
                                <div className="locationBtn">
                                  <a href="https://goo.gl/maps/oi4VykmFDhT5WPSv5" target="_blank" className="themeBtn">Get Directions</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="images/sirsa.jpg" /><h3>Sirsa</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Sirsa</div>
                                <div className="addressInfo"><p>Opp. Telephone Exchange, Post Office road near Lal Batti Chowk Dabwali Road, Sirsa.</p></div>
                                <div className="contactNo">
                                    <a style={{color:'grey'}} href="tel:919878611915" >+91 987 861 1915</a>
                                </div>
                                <div className="locationBtn">
                                  <a href="https://goo.gl/maps/Yw1bGJHqnTvBfPUC6" target="_blank" className="themeBtn">Get Directions</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="images/sriganganagar.jpg" /><h3>Sri Ganganagar</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Sri Ganganagar</div>
                                <div className="addressInfo"><p>8 Mukharjee Nagar, Behind Main Bus Stand, Sri Ganganagar.</p></div>
                                <div className="contactNo">
                                    <a style={{color:'grey'}} href="tel:919915411918" >+91 991 541 1918</a>
                                </div>
                                <div className="locationBtn">
                                  <a href="https://goo.gl/maps/KJ35gVZyyPuBk5Ht6" target="_blank" className="themeBtn">Get Directions</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="images/kurukshetra.jpg" /><h3>Kurukshetra</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Kurukshetra</div>
                                <div className="addressInfo"><p>A-Block, Ground Floor, Divine City Centre, Pipli Road, Near Bus Stand, Kurukshetra.</p></div>
                                <div className="contactNo">
                                    <a style={{color:'grey'}} href="tel:919878111916" >+91 987 811 1916</a>
                                </div>
                                <div className="locationBtn">
                                  <a href="https://goo.gl/maps/V6359HAHpPyxRjzU7" target="_blank" className="themeBtn">Get Directions</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="images/barnala.png" /><h3>Barnala</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Barnala</div>
                                <div className="addressInfo"><p>First floor, Oppsite Kapson Showroom, Nanaksar Gurudwara Sahib, <br/>BARNALA.</p></div>
                                <div className="contactNo">
                                    <a style={{color:'grey'}} href="tel:917658811919" >+91 7658811919</a>
                                </div>
                                <div className="locationBtn">
                                  <a href="https://goo.gl/maps/7NVR6xxsPnPXBGMH9" target="_blank" className="themeBtn">Get Directions</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="adresssBlockInr">
                            <div className="mapOuter">
                                <img src="images/nepal.webp" /><h3>Butwal (Nepal)</h3>
                            </div>
                            <div className="adressCntnt">
                                <div className="businessName">LA Language Academy - Best PTE Institute and Coaching Classes in Butwal (Nepal)</div>
                                <div className="addressInfo"><p>Butwal -11, Kalikangr, Kalikapath- Opposite of Siddhartha Hardware, Rupandehi, <br/>Nepal.</p></div>
                                <div className="contactNo">
                                    <a style={{color:'grey'}} href="tel:97771591305" >+977 71591305</a>
                                </div>
                                <div className="locationBtn">
                                  <a href="https://goo.gl/maps/VzNEQpK3XPBsDE9d9" target="_blank" className="themeBtn">Get Directions</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-sm-12 div20task text-center">
                        <h4>For online classes, please call <a href="tel:61455566654">+61 455 566 654</a>
                        </h4>
                        <h4>For online classes in India, please call <a href="tel:917652811912">+91 765 281 1912</a></h4>
                    </div>
                </div>
            </div>
        </section>
    <Footer />
    </React.Fragment>);
    }
}
export default Branches;
