import React from 'react';
import { Helmet } from "react-helmet";
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Sitemap extends React.Component {
    componentDidMount() {
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
    }
 render(){
    return (
    <React.Fragment>
    <Helmet>
        <title>Sitemap | LA Language Academy India</title>
        <meta name="description" content="Use the LA Language Academy sitemap to easily find your way around our website. Quickly locate the information and resources you need." />
    </Helmet>
    <Header />
    <div class="innerPage">
    <section class="bannerOuter">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1> Sitemap </h1>
                    <span></span><br />
                    <a href="javascript:;" class="downArrow"><img src="/images/banner-arrow.png" alt="banner-arrow" /></a>
                </div>
            </div>
        </div>
    </section>
    </div>
    <section id="pte-modules" className="mt-5">
        <div class="container">
            <div class="row">
            <div class="col-md-6">
          <section id="la_info" class="sitemap_section">

            <h2>Information</h2>
            <div class="row">
              <div class="col-md-12">
                <ul>
                  <li><a target="_blank" class="sitemap_href" href="/">Home</a></li>
                  {/*<li><Link target="_blank" to="/video-courses">Video Course</Link></li>*/}
                  <li><Link target="_blank" class="sitemap_href" to="/term">Terms</Link></li>
                  <li><Link target="_blank" class="sitemap_href" to="/refund">Refund Policy</Link></li>
                  <li><Link target="_blank" to="/about">About Us</Link></li>
                  <li><Link target="_blank" to="/contact">Contact</Link></li>
                </ul>
              </div>
            </div>
          </section>
          <section id="la_courses" class="sitemap_section">

            <h2>Courses</h2>
            <div class="row">
              <div class="col-md-12">
                <ul>

                  <li>
                     <a class="test1">PTE </a>
                     <ul className="ml-5">
                        <li><a target="_blank" href="/pte-online-coaching">PTE Online Coaching</a></li>
                        <li><a target="_blank" href="/pte-coaching-classes-parramatta">PTE Parramatta</a></li>
                        <li><a target="_blank" href="/pte-coaching-sydney">PTE Sydney</a></li>
                        <li><a target="_blank" href="/pte-coaching-classes-jalandhar">PTE Jalandhar</a></li>
                        <li><a target="_blank" href="/pte-coaching-classes-gurdaspur">PTE Gurdaspur</a></li>

                     </ul>
                  </li>
                  <li>
                     <a class="test1" >NAATI </a>
                     <ul className="ml-5">
                        <li><a target="_blank" href="/naati-ccl-online-coaching">NAATI Online Coaching</a></li>
                        <li><a target="_blank" href="/naati-coaching-classes-parramatta">NAATI Parramatta</a></li>
                        <li><a target="_blank" href="/naati-ccl-coaching-sydney">NAATI Sydney</a></li>

                     </ul>
                  </li>
                  <li>
                     <a class="test1" >IELTS </a>
                     <ul className="ml-5">
                        <li><a target="_blank" href="/ielts-online-coaching">IELTS Online Coaching</a></li>
                        <li><a target="_blank" href="/ielts-coaching-parramatta">IELTS Parramatta</a></li>
                        <li><a target="_blank" href="/ielts-coaching-sydney">IELTS Sydney</a></li>

                     </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>


        </div>
          <div class="col-md-6">
            <section id="la_resources" class="sitemap_section">

            <h2>Resourses</h2>
            <div class="row">
              <div class="col-md-12">
                <ul>
                  <li><a target="_blank" class="sitemap_href" href="https://www.languageacademy.com.au/a/videos/showvideo">Tutorial Videos</a></li>
                </ul>
              </div>
            </div>
          </section>
          </div>
        </div>
        </div>
      </section>
    <Footer />
    </React.Fragment>);
    }
}

export default Sitemap;
