import React,{Component} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import API from "api.js";

import { thead, tbody } from "variables/general";

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    API({
        method: 'GET',
        url: 'transaction'
      }).then(data => {
        console.log(data)
        this.setState({'data':data.data.data});
    }).catch(()=>{
      
    });

  }
 
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Transactions</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="transactionOuter">
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        
                              <th className="">
                                Title
                              </th>
                              <th className="">
                                Amount
                              </th>
                              <th className="">
                                Charge Id
                              </th>
                              <th className="">
                                Status
                              </th>
                              <th className="">
                                Expired
                              </th>
                            
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.data.length ===0 && <tr><td colSpan="5" className="">
                                    No Data Found
                                  </td></tr>}
                      {this.state.data.map((val, key) => {
                        return (
                          <tr key={key}>
                                  <td className="">
                                    {val.type === 1 && "Subscription" } Plan - {val.subscription && val.subscription.plan && val.subscription.plan.title }
                                  </td>
                                  <td className="">
                                    {val.amount}
                                  </td>
                                  <td className="">
                                    {val.charge_id}
                                  </td>
                                  <td className="">
                                    <p className="text-success">Success</p>
                                  </td>
                                  <td className="">
                                    <p className="text-success">{val.subscription && val.subscription.status === 1 ? 'No' : 'Yes'}</p>
                                  </td>
                                
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>                
                  </div>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default Transaction;
