import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,Modal, ModalHeader, ModalBody, ModalFooter,Table
} from "reactstrap";
import Header from '../../../components/pteportal/Header';
import Footer from '../../../components/pteportal/Footer';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { Redirect, NavLink } from "react-router-dom";
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import SimpleReactValidator from 'simple-react-validator';
import "components/custom.css";
/*import DatePicker from "react-datepicker";*/
import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";
import API from "api.js";
import DatePicker from 'react-date-picker';
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      cpassword: '',
      phone: '',
      userName: '',
      firstName: '',
      lastName: '',
      countryCitizenship:'',
      countryResidence:'',
      dob: '',
      center:'',
      score:'',
      otp:'',
      isOtp:false,
      isInstitude:true,
      login:false,
      error:null,
      modal:false,
      error_bag:null,
      dobs:''
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    // ReactGA.initialize('UA-157984080-1');
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('3847665161963731');
    ReactPixel.pageView();
    document.body.classList.toggle("register-page");

  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  sendOTP = async(e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }

    this.setState({error_bag:null});
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('password', this.state.password);

    bodyFormData.set('center', (this.state.isInstitude)?this.state.center:"" );
    bodyFormData.set('phone', this.state.phone);
    bodyFormData.set('score', this.state.score);
    bodyFormData.set('first_name', this.state.firstName);
    bodyFormData.set('last_name', this.state.lastName);
    bodyFormData.set('dob', this.state.dob);
    bodyFormData.set('country_citizenship', this.state.countryCitizenship);
    bodyFormData.set('country_residence', this.state.countryResidence);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/send/otp',
        data: bodyFormData
      })
    }catch(error) {
        this.setState({error:{error} });
    };
    console.log(res);
    if (res.status === 200) {
      this.setState({'isOtp':true});
      store.dispatch(setAlert({'flag':true,'type':1,'message':"Please check your email for otp."}));
      console.log(res);
      //store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
    }else if (res.response.status === 422) {
      this.setState({error:res.response });
      let str = '';
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str});
    }

  }

  sendOTP1 = async(e) => {


    this.setState({error_bag:null});
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('center', (this.state.isInstitude)?this.state.center:"" );
    bodyFormData.set('phone', this.state.phone);
    bodyFormData.set('score', this.state.score);
    bodyFormData.set('first_name', this.state.firstName);
    bodyFormData.set('last_name', this.state.lastName);
    bodyFormData.set('dob', this.state.dob);
    bodyFormData.set('country_citizenship', this.state.countryCitizenship);
    bodyFormData.set('country_residence', this.state.countryResidence);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/send/otp',
        data: bodyFormData
      })
    }catch(error) {
        this.setState({error:{error} });
    };
    console.log(res);
    if (res.status === 200) {
      this.setState({'isOtp':true});
      store.dispatch(setAlert({'flag':true,'type':1,'message':"Please check your email for otp."}));
      console.log(res);
      //store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
    }else if (res.response.status === 422) {
      this.setState({error:res.response });
      let str = '';
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str});
    }

  }



  postSignUp = async(e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }
    this.setState({error_bag:null});
    e.preventDefault();
    let cred = {
      email: this.state.email,
      password: this.state.password,
    };
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('center', (this.state.isInstitude)?this.state.center:"" );
    bodyFormData.set('phone', this.state.phone);
    bodyFormData.set('score', this.state.score);
    bodyFormData.set('first_name', this.state.firstName);
    bodyFormData.set('last_name', this.state.lastName);
    bodyFormData.set('dob', this.state.dob);
    bodyFormData.set('otp', this.state.otp);
    bodyFormData.set('country_citizenship', this.state.countryCitizenship);
    bodyFormData.set('country_residence', this.state.countryResidence);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/signup',
        data: bodyFormData
      })
    }catch(error) {

        this.setState({error:{error} });
    };
    console.log(res);
    if (res.status === 200) {
      this.setState({'login':true});
      store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
    }else if (res.status === 202) {
      store.dispatch(setAlert({'flag':true,'type':2,'message':res.data.message}));
    }else if (res.response.status === 422) {
      this.setState({error:res.response });
      let str = '';
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str});
    }


  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  toggleModal = (e) => {
    this.setState({modal: !this.state.modal });
  };

  changeCheckInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : !this.state.isInstitude });
  };


  changeDate = (date) =>{
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    console.log([year, month, day].join('-'));
    this.setState({dob:[year, month, day].join('-'),dobs:date});
  }
  render() {
    if (this.state.login) {
        return (<Redirect to="/register-success" />);
    }
    return (
      <React.Fragment>

      <Helmet htmlAttributes>
        <title>Register Language Academy</title>
        <meta name="twitter:title" content="Register Language Academy" />
        <meta property="og:title" content="Register Language Academy" />
        <meta name="description" content="Register your free account now at Language Academy to obtain your Free Mock Test Material." />

        <meta name="twitter:description" content="Register your free account now at Language Academy to obtain your Free Mock Test Material." />
        <meta property="og:description" content="Register your free account now at Language Academy to obtain your Free Mock Test Material." />
      </Helmet>
      <section class="header-with-banner">
        <Header />
        <section id="hero about-us-page" class="d-flex align-items-center">
        <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 mb-5">
              <h2 class="main-heading pt-5 text-center">Sign-up </h2>
              <div class="breadcum">
                <ol class="breadcrumb primary-color text-center">
                    <li class="breadcrumb-item"><a class="white-text" href="#">Home</a></li>
                    <li class="breadcrumb-item active">Sign-up</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
    </section>
        </section>
        <main id="main" class="avout-page">
      <div className="">


{!this.state.isOtp && <section class="contentOuter">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 registerForm">

        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}

          <div class="fieldOuter">
            <Input
                placeholder="First Name"
                type="text"
                name="firstName"
                value={this.state.firstName}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('First name', this.state.firstName, 'required')}
          </div>
          <div class="fieldOuter">
              <Input
                placeholder="Last Name"
                type="text"
                name="lastName"
                value={this.state.lastName}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('Last name', this.state.lastName, 'required')}
          </div>
          <div class="fieldOuter">
            <DatePicker
              onChange={date => {this.changeDate(date)} }
              value={this.state.dobs}
              format="yyyy-MM-dd"
              yearPlaceholder = "D.O.B. (yyyy"
              monthPlaceholder = "mm"
              dayPlaceholder = "dd)"
            />

            {this.validator.message('dob', this.state.dob, 'required')}
          </div>
          <div class="fieldOuter">
              <Input
                placeholder="Country of Citizenship"
                type="text"
                name="countryCitizenship"
                value={this.state.countryCitizenship}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('Country of citizenship', this.state.countryCitizenship, 'required')}
          </div>
          <div class="fieldOuter">
            <Input
              placeholder="Country of Residence"
              type="text"
              name="countryResidence"
              value={this.state.countryResidence}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Country of residence', this.state.countryResidence, 'required')}
          </div>
          <div class="fieldOuter">
            <Input
              placeholder="Email"
              type="email"
              name="email"
              value={this.state.email}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Email', this.state.email, 'required|email')}
          </div>
          <div class="fieldOuter">
            <Input
              placeholder="Phone"
              type="number"
              name="phone"
              value={this.state.phone}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Phone', this.state.phone, 'required')}
          </div>
          <div class="fieldOuter">
            <Input
              placeholder="Password"
              type="password"
              name="password"
              value={this.state.password}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Password', this.state.password, 'required')}
          </div>
          <div class="fieldOuter">
            <Input
              placeholder="Confirm Password"
              type="password"
              name="cpassword"
              value={this.state.cpassword}
              onChange={(e) => { this.changeInput(e)} }
            />
            {this.validator.message('Confirm password', this.state.cpassword, 'required')}
            </div>
            <br />


          {this.state.isInstitude && <React.Fragment><div class="fieldOuter">
                      <select onChange={(e) => { this.changeInput(e)} } name="center" >
                        <option value="">Select branch</option>
                        <option value="LA Parramatta Branch">LA Parramatta Branch</option>
                        <option value="LA Sydney CBD Branch">LA Sydney CBD Branch</option>
                        <option value="LA Jalandhar Branch">LA Jalandhar Branch</option>
                        <option value="LA Online Student">LA Online Student</option>
                        <option value="Not a student with LA">Not a student with LA</option>
                      </select>
                      <em className="info-txt ml-1">*this information must be 100% appropriate otherwise your account may be blocked</em>
                      {this.validator.message('institute center', this.state.center, 'required')}
                    </div>

          </React.Fragment>}
          <React.Fragment><div class="fieldOuter">
                      <select onChange={(e) => { this.changeInput(e)} } name="score" >
                        <option value="">Select desired score</option>
                        <option value="36">36+ (5 Bands)</option>
                        <option value="42">42+ (5.5 Bands)</option>
                        <option value="50">50+ (6 Bands)</option>
                        <option value="58">58+ (6.5 Bands)</option>
                        <option value="65">65+ (7 Bands)</option>
                        <option value="73">73+ (7.5 Bands)</option>
                        <option value="79">79+ (8 Bands)</option>
                      </select>
                      <i style={{margin:'22px 3px'}} onClick={this.toggleModal} class="fa fa-info-circle" aria-hidden="true"></i>
                      {this.validator.message('desired score', this.state.score, 'required')}
                    </div>

          </React.Fragment>
          <div class="fieldOuter text-center mb-0">
            <button class="themeBtn blueBtn" onClick={this.sendOTP}>Signup</button>
          </div>
      </div>
    </div>
  </div>
</section>}
{this.state.isOtp && <section class="contentOuter">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 registerForm">
        <div class="alert alert-success">Please check your email for OTP.</div>
        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
          <div class="fieldOuter">
            <Input
                placeholder="Enter OTP"
                type="text"
                name="otp"
                value={this.state.otp}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('otp', this.state.otp, 'required')}
              <a href="javascript:void(0)" className="text-primary" onClick={this.sendOTP1}>Resend OTP</a>
          </div>

          <div class="fieldOuter text-center mb-0">
            <button class="themeBtn blueBtn" onClick={this.postSignUp}>SIGN UP</button>
          </div>
      </div>
    </div>
  </div>
</section>}

</div>
</main>
<Footer />
      </React.Fragment>
    );
  }
}

export default Register;
