import React from 'react';
import {store,persistor} from 'store/index';
import { Link } from 'react-router-dom';
function Footer(props) {
	return (
        <React.Fragment>
		<footer id="footer" class="pt-5 pb-5">

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-3 col-xs-12 footer-contact-text">
            <a href="#">Pteportal.<span class="dot-com">com</span></a>
            <p class="link-pehra">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
            </p>
          </div>

          <div class="col-lg-6 col-md-6 col-xs-12 footer-links">
              <h4 class="footer-sub-heading">Quick Links</h4>
              <div className="row">
                <div class="col-lg-6 col-md-6" >
                    <ul className='all-navigation-links' >
                        <li><a href='#'> Home</a></li>
                        <li><a href='#'>PTE Mock Test</a></li>
                        <li><a href='#'>Pricing</a></li>
                        <li><a href='#'>Speaking</a></li>
                        
                      </ul>
                </div>
                <div class="col-lg-6 col-md-6" >
                    <ul className='all-navigation-links' >
                        
                        <li><a href="#">Reading</a></li>
                        <li><a href="#">Writing</a></li>
                        <li><a href="#">Listening</a></li>
                        <li><a href="#">FAQs</a></li>
                      </ul>
                </div>
              </div>
              
          </div>

          

          <div class="col-lg-3 col-md-3 col-xs-12 footer-links">
              <h4 class="footer-sub-heading">Connect With Us</h4>
              <ul class="all-navigation-links">
                <li><a href="#"> P: (+91) ---------</a></li>
                <li><a href="#">E: ----------</a></li>
                <li><a href="#">P: ---------</a></li>
              </ul>
              <div class="social-links text-md-left pt-3 pt-md-0">
                <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12 footer-links">   
          <div class="mr-md-auto text-center text-center">
            <div class="copyright">
              &copy; Copyright <strong><span>Pteportal</span></strong>. All Rights Reserved
            </div>
          </div>
        </div>  
      </div>
    </div>
  </footer>
        </React.Fragment>
	);
}

export default Footer;