import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/Frontend/Header";
import Footer from "../components/Frontend/Footer";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";


function SampleNextArrow(props) {
  const { className, style, onClick } = props; 
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}


export class Pteresourcepage extends Component {
    constructor(props){
        super();
        this.state={

        }

    }
  render() {
    
     const settings = {
       dots: true,
       infinite: false,
       //  centerMode: true,
       speed: 500,
       slidesToShow: 3,
       slidesToScroll: 1,
       arrows: true,
       nextArrow: <SampleNextArrow />,
       prevArrow: <SamplePrevArrow />,
       variableWidth: true,
     };


    return (
      <React.Fragment>
        <Helmet>
          <title>Language Academy India PTE Resource Page</title>
          <meta name="description" content="Explore a wide range of PTE study materials on LA Language Academy's resource page. Find essential tools for effective and successful exam preparation." />
        </Helmet>
        <div className="homePage">
          <Header />
        </div>
        <section className="homeBanner">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 my-auto">
                <h1>
                  Enrol at the <span>Top NAATI & PTE Training Institute</span>{" "}
                  in India and nail the exam like a pro
                </h1>
                <h2>
                  Attend a free trial class at our PTE / NAATI CCL coaching
                  <br /> centre in Jalandhar, India and feel the difference
                </h2>
                <Link to="/auth/register" className="themeBtn">
                  Register
                </Link>
                <Link to="/contact" className="themeBtn blueBtn">
                  Contact us
                </Link>
              </div>
              <div className="col-sm-6">
                <img src="images/bannerImg.png" alt="bannerImg" />
              </div>
            </div>
          </div>
        </section>

        <div
          style={{  
            // backgroundColor: "grey",
            position: "relative",
            zIndex: "12",
            width: "95%",
          }}
          className="mx-auto testimonial-carousal mt-3"
        >
          <h2
            style={{
              fontSize: "32px",
              color: "#18294d",
              marginLeft: "30px",
              fontWeight: "600px",
            }}
          >
            Youtube Channels
          </h2>
          <Slider {...settings}>
            <div className=" la-free-mt-app-testimonial-col3 ">
              <div className="single-testimonial la-video-testimonial">
                <iframe
                  width="310"
                  height="246"
                  src="https://www.youtube.com/embed/fxQ7iPQJLCc"
                  title={"PTE training video"}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className=" la-free-mt-app-testimonial-col4">
              <div className="single-testimonial la-video-testimonial">
                <iframe
                  width="310"
                  height="246"
                  src="https://www.youtube.com/embed/m5yHxQTGBUI"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className=" la-free-mt-app-testimonial-col4">
              <div className="single-testimonial la-video-testimonial">
                <iframe
                  width="310"
                  height="246"
                  src="https://www.youtube.com/embed/yQm5J3B-xH4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className=" la-free-mt-app-testimonial-col4">
              <div className="single-testimonial la-video-testimonial">
                <iframe
                  width="310"
                  height="246"
                  src="https://www.youtube.com/embed/qw1EZlDgGhA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </Slider>
        </div>

        <div
          style={{
            // backgroundColor: "grey",
            position: "relative",
            zIndex: "12",
            width: "95%",
          }}
          className="mx-auto testimonial-carousal mt-3"
        >
          <h2
            style={{
              fontSize: "32px",
              color: "#18294d",
              marginLeft: "30px",
              fontWeight: "600px",
            }}
          >
            Free online courses
          </h2>
          <Slider {...settings}>
            <div className=" la-free-mt-app-testimonial-col3 ">
              <div className="single-testimonial la-video-testimonial">
                <iframe
                  width="310"
                  height="246"
                  src="https://www.youtube.com/embed/fxQ7iPQJLCc"
                  title={"PTE training video"}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className=" la-free-mt-app-testimonial-col4">
              <div className="single-testimonial la-video-testimonial">
                <iframe
                  width="310"
                  height="246"
                  src="https://www.youtube.com/embed/m5yHxQTGBUI"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className=" la-free-mt-app-testimonial-col4">
              <div className="single-testimonial la-video-testimonial">
                <iframe
                  width="310"
                  height="246"
                  src="https://www.youtube.com/embed/yQm5J3B-xH4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className=" la-free-mt-app-testimonial-col4">
              <div className="single-testimonial la-video-testimonial">
                <iframe
                  width="310"
                  height="246"
                  src="https://www.youtube.com/embed/qw1EZlDgGhA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </Slider>
        </div>

        <section
          style={{
            
            width: "95%",
          }}
          className="py-30 mt-4"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="pl-40 pr-40 text-center">
                  <h2>Free PTE mock tests</h2>
                  <p>
                    We will be providing the top 5 free Pte mock tests you
                    should try in this section by adding our page at the topmost
                    place.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div
              // style={{ display: "flex" }}
              className="row  aligns-items-center text-center"
            >
              <div className="col-md-4 la-free-mt-app-features-innerr-col1 la-free-mt-app-features-innerr-col">
                <div class="la-inner-col">
                  <h2>Real-time Test Environment</h2>
                  <p>
                    Preparing with our best PTE mock test helps you to gain real
                    test experience beforehand.{" "}
                  </p>
                </div>
              </div>

              <div class="col-md-4 la-free-mt-app-features-innerr-col2 la-free-mt-app-features-innerr-col">
                <div class="la-inner-col">
                  <h2>Detailed Analytics</h2>
                  <p>
                    After taking the PTE online mock test, the portal provides
                    detailed insights into what went wrong and how you can
                    improve further.
                  </p>
                </div>
              </div>

              <div class="col-md-4 la-free-mt-app-features-innerr-col3 la-free-mt-app-features-innerr-col">
                <div class="la-inner-col">
                  <h2>Accurate Scoring Algorithms</h2>
                  <p>
                    The AI-Powered algorithm provides the scorecard within
                    seconds along with the mistakes and corrections.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-30 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="pl-40 pr-40 text-center">
                  <h2>PTE Academic Handbook or E-book</h2>
                  <p>
                    PTE Academic Handbook is a downloadable PDF, which is
                    available free of cost. Also, you will get precise details
                    about how to book the test, what to expect on exam day, and
                    get a transparent outline of the exam.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row  aligns-items-center text-center">
              <div className="col-md-4 la-free-mt-app-features-innerr-col1 la-free-mt-app-features-innerr-col">
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    style={{ width: "100%" }}
                    variant="top"
                    src="https://colorlib.com/wp/wp-content/uploads/sites/2/mockup-of-a-man-holding-a-hardcover-book.jpg.webp"
                  />
                  <Card.Body>
                    <Card.Title>PTE E-Book</Card.Title>
                    <Card.Text>
                      Preparing with our best PTE mock test helps you to gain
                      real test experience beforehand.
                    </Card.Text>
                    <Button variant="primary">View more</Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-4 la-free-mt-app-features-innerr-col1 la-free-mt-app-features-innerr-col">
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    style={{ width: "100%" }}
                    variant="top"
                    src="https://colorlib.com/wp/wp-content/uploads/sites/2/horizontal-spiral-book-binding-mockups.jpg.webp"
                  />
                  <Card.Body>
                    <Card.Title>PTE Hand Book</Card.Title>
                    <Card.Text>
                      Preparing with our best PTE mock test helps you to gain
                      real test experience beforehand.
                    </Card.Text>
                    <Button variant="primary">View more</Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-4 la-free-mt-app-features-innerr-col1 la-free-mt-app-features-innerr-col">
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    style={{ width: "100%" }}
                    variant="top"
                    src="https://colorlib.com/wp/wp-content/uploads/sites/2/horizontal-hardcover-book-mockup.jpg"
                  />
                  <Card.Body>
                    <Card.Title>PTE E-Book</Card.Title>
                    <Card.Text>
                      Preparing with our best PTE mock test helps you to gain
                      real test experience beforehand.
                    </Card.Text>
                    <Button variant="primary">View more</Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </section>
        <section className="py-30 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Card>
                  <Card.Header>Tips & Tricks</Card.Header>
                  <Card.Body>
                    <Card.Title>Facebook groups and community</Card.Title>
                    <Card.Text>
                      we will going to mention the top 5 Facebook groups and
                      community pages about Pte practice tips and tricks
                    </Card.Text>
                    <Card.Link href="#">
                      https://www.facebook.com/groups/688615171510815/
                    </Card.Link>
                    <Card.Link href="#">
                      https://www.facebook.com/groups/688615171510815/
                    </Card.Link>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Pteresourcepage