import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Refund extends React.Component {
    componentDidMount() {
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        // ReactPixel.init('197333187452630');
        ReactPixel.init('3847665161963731');
        ReactPixel.track('Lead');
        ReactPixel.track('Purchase', {currency: 'USD'});
        ReactPixel.pageView();
    }
 render(){
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Language Academy</title>
     </Helmet>
    <Header />
    <div class="innerPage">
    <section class="bannerOuter">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <div class="thankyou-inner">

                 <h2 class="header"><b><i class="fa fa-check-circle" aria-hidden="true"></i> &nbsp;&nbsp;Thank You!</b></h2>
                 <p>Your query submitted successfully<br/>
                 </p>
                 </div>
                    <span></span><br />

                </div>
            </div>
        </div>
    </section>
    </div>
    <section id="pte-modules" className="mt-5">
         <div class="container">
            <div class="row text-center">



            </div>
      </div>
      </section>
    <Footer />
    </React.Fragment>);
    }
}

export default Refund;
