import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import API from "api.js";
import { NavLink } from "react-router-dom";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";

class ChromeCheckGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      chrome:false,
      microphone:true
    };
  }
  componentDidMount() {
    let chrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/Edg/.test(navigator.userAgent)  && !/Chromium/.test(navigator.userAgent);
    if (navigator.getUserMedia === undefined) {
      this.setState({microphone:false,chrome:chrome})
    }else{
      navigator.getUserMedia({ audio: true },
        () => {                   
          console.log('Permission Granted');
          this.setState({microphone:true});
        },
        () => {
          console.log('Permission Denied');
          this.setState({microphone:false})
        },
      );  
    }  
    
    this.setState({ chrome:chrome});


  }
 
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={6}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Chrome browser</CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                
                  {this.state.chrome && <i className="fa fa-check-circle " style={{color:'green',fontSize: '100px'}}></i>}
                  {!this.state.chrome && <React.Fragment> 
                    <i className="fa fa-times-circle " style={{color:'red',fontSize: '100px'}}></i>
                    <br />
                    <em>*Please open chrome browser for practice question(s)</em>
                    </React.Fragment>
                  }
                   
                </CardBody>
              </Card>
            </Col>
            <Col xs={6}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Check Microphone</CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                
                  {this.state.microphone && <i className="fa fa-check-circle " style={{color:'green',fontSize: '100px'}}></i>}
                  {!this.state.microphone && <React.Fragment> 
                    <i className="fa fa-times-circle " style={{color:'red',fontSize: '100px'}}></i>
                    <br />
                    <em></em>
                    </React.Fragment>
                  }
                   
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>
        </div>
      </>
    );
  }
}

export default ChromeCheckGlobal;
