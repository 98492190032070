import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import API,{base} from "api.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// reactstrap components
import { setAlert} from 'action/index.js';
import { setUser} from 'action/index.js';
import {store,persistor} from 'store/index';
import moment from 'moment'
import Cookies from 'universal-cookie';
import { confirmAlert } from 'react-confirm-alert';
import Countdown from 'react-countdown';
import { Helmet } from "react-helmet";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Container,
  Form,
  Alert,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,NavLink as NavLink1,
  TabContent, TabPane, Nav, NavItem,CardText,
  UncontrolledTooltip
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
  chartExample5,
  chartExample6,
  chartExample7,
  chartExample8
} from "variables/charts.jsx";
const cookies = new Cookies();

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};
const renderer = ({ hours, minutes, seconds, completed }) => {
    return <span>{hours}:{minutes}:{seconds}</span>;
};

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data:false,
      activeTab:1,
      targetModal:false,
      examModal:false,
      exanDate:null,
      speaking:null,
      reading:null,
      writing:null,
      diff_days: null,
      first_name: null,
      last_name: null,
      country_citizenship:null,
      country_residence:null,
      dob: null,
      email: null,
      phone: null,
      address: null,
      lang: null,
      center: null,
      score: null,
      listening:null,
      exam_date:null,
      overall_score:null,
      modal1:false,
      announceModal:false,
      isSubscribed:false,
      isPopupQuery:0,
      isInstitute: false,
      institute: null,
      isMainSite:store.getState().is_main,
      guestFlag:true,
      showDiscountPopup:false,
      anySub:0,
      active_subscription:null,
      dashboardData:false,
      btcModal:false,
      btcModalState:false,
      btc_name:'',
      btc_phone:'',
      btc_email:'',
      btc_preferred_mode:'',
      btc_desired_score:'',
      btc_error_bag:'',
      btc_success_bag:''
    }
  }



  guestCta = async(e) => {
    var bodyFormData = new FormData();
    bodyFormData.set('popup_query', 1);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: 'send/details/mail',
        data: bodyFormData
      })
    }catch(error) {
        this.setState({error:{error} });
    };
    if (res.status === 200) {
      this.props.setUser(res.data.response.user);      
      store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message}));  
    }
    // API({
    //     method: 'POST',
    //     url: 'send/details/mail',
    //     data: bodyFormData
    //   }).then(data => {
        
    // });
    this.setState({'guestFlag':false})
        confirmAlert({
          title: '',
          message: 'Thank You! We will contact you shortly… ',
          buttons: [
            
            {
              label: 'Okay',
              onClick: () => {}
            }
          ]
        });
  }

  targetToggle = () =>{
    this.setState({targetModal:!this.state.targetModal});
  }

  examToggle = () =>{
    this.setState({examModal:!this.state.examModal});
  }
  componentDidMount() {
    API({
      method: 'GET',
      url: 'get_dashboard_data'
    }).then(data => {
      var dashboardData = data.data.data;
      console.log(dashboardData);
      if(dashboardData.user_attempted_questions === '' || dashboardData.user_attempted_questions === null){
        dashboardData.user_attempted_questions = [];
        dashboardData.user_attempted_questions.speaking_questions = 0;
        dashboardData.user_attempted_questions.reading_questions = 0;
        dashboardData.user_attempted_questions.listening_questions = 0;
        dashboardData.user_attempted_questions.writing_questions = 0;
        dashboardData.user_attempted_questions.total_mock_practiced = 0;
        dashboardData.user_attempted_questions.total_ques_practiced = 0;
        dashboardData.user_attempted_questions.practice_days = 0;
      }
      console.log(dashboardData);
      var btc_name = dashboardData.first_name;
      var btc_phone = dashboardData.phone;
      var btc_email = dashboardData.email;
      var btc_desired_score = dashboardData.score;
      this.setState({btc_name:btc_name,btc_phone:btc_phone,btc_email:btc_email,btc_desired_score:btc_desired_score})
      var speaking = dashboardData.speaking;
      var reading = dashboardData.reading;
      var writing = dashboardData.writing;
      var listening = dashboardData.listening;
      var overall_score = (speaking+reading+writing+listening)/4;
      var anysub = dashboardData.anysub;
      var user_type = dashboardData.type;
      var popup_query = dashboardData.popup_query;
      var first_name = dashboardData.first_name;
      var last_name = dashboardData.last_name;
      var phone = dashboardData.phone;
      var address = dashboardData.address;
      var country_citizenship = dashboardData.country_citizenship;
      var country_residence = dashboardData.country_residence;
      var dob = dashboardData.dob;
      var email = dashboardData.email;
      var lang = dashboardData.lang;
      var center = dashboardData.center;
      var score = dashboardData.score;
      var exanDate = dashboardData.exam_date;
      var active_subscription = dashboardData.active_subscription;
      var exam_date = dashboardData.exam_date;
      let startDate = moment(new Date()).format("YYYY-MM-DD");
      let endDate = moment(exam_date, "YYYY-MM-DD");
      let result = endDate.diff(startDate, 'days');
      var isSubscribed = (dashboardData.active_subscription && dashboardData.active_subscription.length > 0)?true:false;
      var isInstitute = (dashboardData.active_subscription && dashboardData.active_subscription.length > 0 && dashboardData.active_subscription[0].plan.status === 0 )?true:false
      var institute = dashboardData.institute;
      this.setState({exam_date:exam_date,isSubscribed:isSubscribed,diff_days:result,isInstitute:isInstitute,institute:institute,active_subscription:active_subscription})
      if(user_type === 1 || user_type === 2){
        if(this.state.anySub !== 1){
          if(popup_query !== 1){
            this.setState({showDiscountPopup:true})
          }
        }
      }
      this.setState({speaking:speaking,reading:reading,writing:writing,listening:listening,overall_score:overall_score,dashboardData:dashboardData,anySub:anysub,isPopupQuery:popup_query,first_name:first_name,last_name:last_name,phone:phone,address:address,country_citizenship:country_citizenship,country_residence:country_residence,dob:dob,email:email,lang:lang,center:center,score:score,exanDate:exanDate})
      let announceModal = false;
      let examModal = false;
      if(dashboardData.announcement1 && dashboardData.announcement1.length > 0){
        announceModal = true;
      }
      if(this.state.isInstitute && cookies.get('exam-date') === undefined && (!this.state.exam_date  || this.state.diff_days <0 ) ){
        cookies.set('exam-date', '1', { path: '/' });
        announceModal = false;
        examModal = true; 
      }
      this.setState({data:dashboardData,announceModal:announceModal,examModal:examModal});
      this.checkVideoSubscription();
      if (!this.state.institute && (this.state.score == null || this.state.score == 'null' || this.state.center == 'null' || this.state.center == null || !this.state.lang || !this.state.address || !this.state.phone || !this.state.email || !this.state.country_residence || !this.state.dob || !this.state.country_citizenship)) {
        this.setState({modal:true});
      }else{
        this.setState({modal:false});
      }
    }).catch(()=>{
    });
    // API({
    //     method: 'GET',
    //     url: 'dashboard/data'
    //   }).then(data => {
    // }).catch(()=>{
    // });
    this.timerFunc();    
    this.validator = new SimpleReactValidator();
  }

  timerFunc(){
        /*var countDownDate = new Date("Apr 4, 2021 16:37:52").getTime();

        // Run myfunc every second
        var myfunc = setInterval(function() {

        var now = new Date().getTime();
        var timeleft = countDownDate - now;
            
        // Calculating the days, hours, minutes and seconds left
        var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
             
        if (document.querySelectorAll('#timer-id').length > 0) {
            document.getElementById("timer-id").innerHTML= days+' Days, '+hours+':'+minutes+':'+seconds+' Left';    
        }
        
            
        // Display the message when countdown is over
        if (timeleft < 0) {
            clearInterval(myfunc);
        }
        });
*/    }

  loadMoredata(){
    API({
        method: 'GET',
        url: 'dashboard/data2'
      }).then(data => {
        
        this.setState({data:data.data.data});
        
    }).catch(()=>{
      
    });
  }

  checkVideoSubscription =() => {
    if(false && this.state.active_subscription.length >0 && this.state.active_subscription[0].package_id === 9){
      this.setState({modal1:true});
    }

  }
  saveTarget = async(e) => {
    
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.setState({error_bag:null});
    e.preventDefault();
    
    var bodyFormData = new FormData();
    bodyFormData.set('speaking', this.state.speaking);
    bodyFormData.set('listening', this.state.listening);
    bodyFormData.set('reading', this.state.reading);
    bodyFormData.set('writing', this.state.writing);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/updateTarget',
        data: bodyFormData
      })
    }catch(error) {
        this.setState({error:{error} });
    };
    if (res.status === 200) {
      this.props.setUser(res.data.response.user);
      this.setState({examModal:false,targetModal:false,overall_score:(res.data.response.user.speaking+res.data.response.user.reading+res.data.response.user.writing+res.data.response.user.listening)/4});
      
      store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message}));       
    }else if (res.response.status === 422) {
      this.setState({error:res.response });
      let str = '';
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str});
    }
    
    
  }

  saveTargetDate = async(e) => {
    
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.setState({error_bag:null});
    e.preventDefault();
    
    var bodyFormData = new FormData();
    bodyFormData.set('exam_date', this.state.exam_date);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/updateTarget',
        data: bodyFormData
      })
    }catch(error) {
        this.setState({error:{error} });
    };
    if (res.status === 200) {
      this.props.setUser(res.data.response.user);
      let exam_date = res.data.response.user.exam_date !== 'undefined'?res.data.response.user.exam_date:'';
      let startDate = moment(new Date()).format("YYYY-MM-DD");
      let endDate = moment(exam_date, "YYYY-MM-DD");
      let result = endDate.diff(startDate, 'days');
      
      this.setState({diff_days:result,examModal:false,targetModal:false,overall_score:(res.data.response.user.speaking+res.data.response.user.reading+res.data.response.user.writing+res.data.response.user.listening)/4});
      
      store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message}));       
    }else if (res.response.status === 422) {
      this.setState({error:res.response });
      let str = '';
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str});
    }
    
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
      this.setState({ [name] : value });  
  };

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab});
    }
  }

  closeModal = () => {
    this.setState({modal1:false});
  }

  closeAnnouncrModal = () => {
    this.setState({announceModal:false});
  }

  closeGuestModal = () => {
    this.setState({guestFlag:false});
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  showbtcModal = () => {
    this.setState({btc_success_bag:''});
    this.setState({btc_error_bag:''});
    this.setState({btcModal:true});
    this.setState({btcModalState:true});
    console.log(this.state);
  }

  closebtcModal = () => {
    this.setState({btc_success_bag:''});
    this.setState({btc_error_bag:''});
    this.setState({btcModal:false});
    this.setState({btcModalState:false});
    console.log(this.state);
  }

  booktrialclassbtn = async(e) => {
    e.preventDefault();
    if(this.state.btc_name == '' || this.state.btc_phone == '' || this.state.btc_email == '' || this.state.btc_preferred_mode == '' || this.state.btc_desired_score == ''){
      this.setState({btc_error_bag:'Please fill all fields!'});
    }else{
      this.setState({btc_error_bag:''});
      var bodyFormData = new FormData();
      bodyFormData.set('name', this.state.btc_name);
      bodyFormData.set('phone', this.state.btc_phone);
      bodyFormData.set('email', this.state.btc_email);
      bodyFormData.set('preferred_mode', this.state.btc_preferred_mode);
      bodyFormData.set('desired_score', this.state.btc_desired_score);
      console.log(bodyFormData);
      console.log(this.state);
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: '/btc-submit-data',
          data: bodyFormData
        })
      }catch(error) {
      };
      console.log(res);
      if (res.status === 200) {
        this.setState({btc_success_bag:'Query Submitted Successfully!'});
        setTimeout(function() {
          window.location.reload();
        }, 1000);
      }else{
        this.setState({btc_error_bag:'Unable to submit query!'});
      }
    }
  }

  
  render() {
    
    return (
      <>
      {this.state.announceModal && <Modal isOpen={this.state.announceModal} id="anouncementPopup" size="lg">
        
              <div class="modal-body">
                <button onClick={(e)=>{this.closeAnnouncrModal()}} type="button" class="close" data-dismiss="modal">&times;</button>
                <div class="mobileIcon">
                  <img src="/images/mobile-img.png" alt="mobile-img" />
                </div>
                <div class="contentInr">
                  <h2>{this.state.dashboardData.announcement1.length > 0 && this.state.dashboardData.announcement1[0].title}</h2>
                  <p>{this.state.dashboardData.announcement1.length > 0 && this.state.dashboardData.announcement1[0].body}</p>
                           
                </div>
              </div>
            
      </Modal>}
      {/* {!this.state.isSubscribed && this.state.showDiscountPopup && <Modal isOpen={this.state.guestFlag} id="anouncementPopup" size="lg">
        
              <div class="modal-body">
                <button onClick={(e)=>{this.closeGuestModal()}} type="button" class="close" data-dismiss="modal">&times;</button>
                <div class="mobileIcon">
                  <img src="/images/mobile-img.png" alt="mobile-img" />
                </div>
                <div class="contentInr">
                  <h2>Great News</h2>
                  <p>50+ students got their desired score with LA's Online Classes last month. Start your journey to success with our Online Classes. Book your slot NOW. *Limited slots available</p>
                   <p style={{ color: 'red',fontWeight: '700'}} id="timer-id"> </p>
                    <button onClick={(e)=>this.guestCta()}>Start Now</button>       
                </div>
              </div>
            
      </Modal>} */}

      {this.state.btcModal && <Modal className="btcpopup" centered="true" isOpen={this.state.btcModalState} size="lg">
                    <ModalBody>
                      <Container>
                        <Row >
                          <Col className="ml-auto mr-auto" lg="12" md="12">
                            <Form action="" className="form" method="">
                              <Card className="card-btc">
                                <CardHeader>
                                  {this.state.btc_success_bag && <Alert color="success">{this.state.btc_success_bag}</Alert>}
                                  {this.state.btc_error_bag && <Alert color="warning">{this.state.btc_error_bag}</Alert>}
                                  <CardHeader>
                                    <h3 className="header text-center">Book Trial Class</h3>
                                    <hr></hr>
                                  </CardHeader>
                                </CardHeader>
                                <CardBody>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="nc-icon nc-single-02" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input 
                                      placeholder="Name"
                                      type="text"
                                      name="btc_name"
                                      value={this.state.btc_name}
                                      onChange={(e) => { this.changeInput(e)} }
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="nc-icon nc-mobile" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      placeholder="Phone"
                                      type="number"
                                      name="btc_phone"
                                      value={this.state.btc_phone}
                                      onChange={(e) => { this.changeInput(e)} }
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="nc-icon nc-email-85" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      placeholder="Email"
                                      type="text"
                                      name="btc_email"
                                      value={this.state.btc_email}
                                      onChange={(e) => { this.changeInput(e)} }
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="nc-icon nc-tap-01" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <select onChange={(e) => { this.changeInput(e)} } class="form-control" name="btc_preferred_mode" >
                                      <option value="">Select Preferred Mode</option>
                                      <option value="online">Online</option>
                                      <option value="on-campus">On Campus</option>
                                    </select>
                                  </InputGroup>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="nc-icon nc-satisfied" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      placeholder="Desired Score"
                                      type="text"
                                      name="btc_desired_score"
                                      value={this.state.btc_desired_score}
                                      onChange={(e) => { this.changeInput(e)} }
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                  <br />
                                </CardBody>
                                <CardFooter className="text-center">
                                  <Button
                                    className="btn-round mb-3"
                                    color="warning"
                                    onClick={(e) => {this.booktrialclassbtn(e)}}
                                  >Submit</Button>
                                </CardFooter>

                              </Card>
                            </Form>
                          </Col>
                        </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" className="modalCloseBtn" onClick={this.closebtcModal}>Close</Button>
                    </ModalFooter>
                  </Modal>}

      {this.state.modal1 && <Modal isOpen={this.state.modal1} size="lg">
        <div className="modalHeader">
          <h2>OPT FOR VIDEO COURSES NOW</h2>
        </div>
        <ModalBody className="video-popup">
          <Row>
            <Col className="" md={12}>
              
                <ul class="checklist">
                  <li>24 hours access.</li>
                  <li>All the proven tips, templates and strategies.</li>
                  <li>Real exam practice questions.</li>
                  <li>Complete access tonline practice portal.</li>
                  <li>3 full length scored mock text with score-report and feedback.</li>
                  <li>One to one consultation session with the tutor. (30 minutes each)</li>
                  <li>Regular prediction files.</li>
                  <li>Access to exam prediction files with highly repeated questions.</li>
                </ul>
            </Col> 
          </Row>
        </ModalBody>
        <ModalFooter>
          <Link to="/video-courses" ><Button color="secondary" className="modalCloseBtn" >SUBSCRIBE</Button></Link>
          <Button color="secondary" onClick={(e)=>{this.closeModal()}} className="modalCloseBtn" >CLOSE</Button>
          
        </ModalFooter>
      </Modal>}
      {this.state.targetModal && <Modal isOpen={this.state.targetModal} size="lg">
        <div className="modalHeader">
          <h2>Set Target</h2>
        </div>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label>Speaking Score</label>
                <Input
                  defaultValue={this.state.speaking}
                  name="speaking"
                  placeholder="Speaking Score"
                  type="text"
                  onChange={(e) => { this.changeInput(e)} }
                />
                {this.validator.message('speaking score', this.state.speaking, 'required|numeric|min:0,num|max:90,num')}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label>Writing Score</label>
                <Input
                  defaultValue={this.state.writing}
                  name="writing"
                  placeholder="Writing Score"
                  type="text"
                  onChange={(e) => { this.changeInput(e)} }
                />
                {this.validator.message('writing score', this.state.writing, 'required|numeric|min:0,num|max:90,num')}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label>Reading Score</label>
                <Input
                  defaultValue={this.state.reading}
                  name="reading"
                  placeholder="Reading Score"
                  type="text"
                  onChange={(e) => { this.changeInput(e)} }
                />
                {this.validator.message('reading score', this.state.reading, 'required|numeric|min:0,num|max:90,num')}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label>Listening Score</label>
                <Input
                  defaultValue={this.state.listening}
                  name="listening"
                  placeholder="Listening Score"
                  type="text"
                  onChange={(e) => { this.changeInput(e)} }
                />
                {this.validator.message('listening score', this.state.listening, 'required|numeric|min:0,num|max:90,num')}
              </FormGroup>
            </Col>
            
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="modalCloseBtn" onClick={this.saveTarget}>Save</Button>
          <Button color="secondary" className="modalCloseBtn" onClick={this.targetToggle}>Close</Button>
        </ModalFooter>
      </Modal>}
      {this.state.examModal && <Modal isOpen={this.state.examModal} size="md">
        <div className="modalHeader">
          <h2>Set Exam Date</h2>
        </div>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label>Exam Date</label>
                <Input
                  defaultValue={this.state.exam_date}
                  name="exam_date"
                  placeholder="Exam date (YYYY-MM-DD)"
                  type="date"
                  onChange={(e) => { this.changeInput(e)} }
                />
                <div className="srv-validation-message">{this.state.error_bag}</div>
                {this.validator.message('exam date', this.state.exam_date, 'required')}
              </FormGroup>
            </Col> 
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="modalCloseBtn" onClick={this.saveTargetDate}>Save</Button>
          <Button color="secondary" className="modalCloseBtn" onClick={this.examToggle}>Close</Button>
        </ModalFooter>
      </Modal>}
      {this.state.modal && <Modal isOpen={this.state.modal} size="md">
        <div className="modalHeader">
          <h2> Please complete your details</h2>
        </div>
        <ModalBody>
          <Row>
            <Col md={12}>
              
            </Col> 
          </Row>
        </ModalBody>
        <ModalFooter>
          <Link to="/admin/profile" ><Button color="secondary" className="modalCloseBtn" >Complete Profile</Button></Link>
          
        </ModalFooter>
      </Modal>}

        <div className="content">
          <Row>

          {this.state.dashboardData && <Carousel showArrows={true} >
              {this.state.dashboardData.announcement.map((val,i) => {
                return (
                  <div>
                    <img src={base + val.image} />
                    <div className="legend">
                      <h3>{val.title}</h3>
                      <p>{val.body}</p>
                  </div>
                </div>
                )
              })
            }
          </Carousel>}
            {/*<Col lg="3" md="6" sm="6">
                          <Card className="card-stats">
                            <CardBody>
                              <Row>
                                <Col md="4" xs="5">
                                  <div className="icon-big icon-warning">
                                    <i className="nc-icon nc-globe text-warning" />
                                  </div>
                                </Col>
                                <Col md="8" xs="7">
                                  <div className="numbers">
                                    <p className="card-category">Question Practiced</p>
                                    <CardTitle tag="p">{this.state.data && this.state.data.question}</CardTitle>
                                    <p />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <div className="stats">
                                
                              </div>
                            </CardFooter>
                          </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                          <Card className="card-stats">
                            <CardBody>
                              <Row>
            
                                <Col md="4" xs="5">
                                  <div className="icon-big icon-warning">
                                    <i className="nc-icon nc-money-coins text-success" />
                                  </div>
                                </Col>
                                <Col md="8" xs="7">
                                  <div className="numbers">
                                    <p className="card-category">Mock Test Attempted</p>
                                    <CardTitle tag="p"> {this.state.data && this.state.data.mock}</CardTitle>
                                    <p />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <div className="stats">
                                
                              </div>
                            </CardFooter>
                          </Card>
                        </Col>
                        
                      */}
                    </Row>
          {this.state.active_subscription == '' && this.state.active_subscription.length == 0 && this.state.showDiscountPopup && <div className="alert alert-warning row"><div className="col-sm-10"><b>Suggestion :</b> More than 107 students got their desired score with LA in the last month. Book your FREE Trial Class NOW.</div><div className="col-sm-2 text-right"><Button color="info" className="bottom-btn" onClick={this.showbtcModal} disabled={false} size="sm">Book Now!</Button></div></div>}
          
          {this.state.diff_days <= 7 && this.state.diff_days > 0 && <div className="alert alert-info"><b>AI Suggestion :</b> You have less than 7 days for your exam. Please start working on weekly predictions.</div>}
          <Col md={12} className="text-right">

            {this.state.isMainSite && <Link to="/video-courses" className="btn btn-success"> Subscribe to another plan</Link>}
            <Link to="/admin/help" className="btn btn-primary"> Help</Link>
            
          </Col>
          <Row>
          	<Col md="5" sm="5">
          		<h3 className="cardTitle">Study Stats</h3>
	          	<div className="userInfoCard green">
	          		<div className="cardHeader">
	          			<div className="leftContent">Exam Date: {this.state.exam_date}</div>
	          			<div className="rightContent"><span>{isNaN(this.state.diff_days)?"-":(this.state.diff_days >0)?this.state.diff_days:0} Days left</span></div>
	          		</div>
	          		<div className="cardBody">
	          			<Row>
	          				<Col className="userRecord"><span>{this.state.dashboardData.user_attempted_questions && this.state.dashboardData.user_attempted_questions.total_mock_practiced}</span>Total Mock Practiced</Col>
	          				<Col className="userRecord"><span>{this.state.dashboardData.user_attempted_questions && this.state.dashboardData.user_attempted_questions.total_ques_practiced}</span>Total Question Practiced</Col>
	          				<Col className="userRecord"><span>{this.state.dashboardData.user_attempted_questions && this.state.dashboardData.user_attempted_questions.practice_days}</span>Prac. Days <Link to="/admin/practice-detail">See History</Link></Col>
	          			</Row>
	          		</div>
	          		<div className="cardFooter">
	          			<Row>
                    <Col><a href="javascript:void()" onClick={this.examToggle}>Set Exam date <i className="fa fa-angle-right" aria-hidden="true"></i></a> </Col>
                  </Row>
	          		</div>
          		</div>
          	</Col>
          	{/*<Col md="2" sm="2">
                          <Card className="card-stats">
                            <CardBody>
                              <Row>
                                <Col md="4" xs="5">
                                  <div className="icon-big icon-warning">
                                    <i className="nc-icon nc-money-coins text-success" />
                                  </div>
                                </Col>
                                <Col md="8" xs="7">
                                  <div className="numbers">
                                    <p className="card-category">Mock Test Attempted</p>
                                    <CardTitle tag="p"> {this.state.data && this.state.data.mock}</CardTitle>
                                    <p />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter>
                              
                            </CardFooter>
                          </Card>
                        </Col>*/}
          	{/*<Col md="7" sm="7">
          		<h3 className="cardTitle">Target</h3>
	          	<div className="userInfoCard purple">
	          		<div className="cardHeader">
	          			<div className="leftContent">Exam Date: 2020-01-23</div>
	          			<div className="rightContent"><span>0 Days left</span></div>
	          		</div>
	          		<div className="cardBody">
	          			<Row>
	          				<Col className="userRecord"><span>{this.state.data && this.state.data.overall_score}</span>Overall</Col>
	          				<Col className="userRecord"><span>{this.state.data && this.state.data.speak_score}</span>Listening</Col>
	          				<Col className="userRecord"><span>{this.state.data && this.state.data.write_score}</span>Reading</Col>
	          				<Col className="userRecord"><span>{this.state.data && this.state.data.read_score}</span>Speaking</Col>
	          				<Col className="userRecord"><span>{this.state.data && this.state.data.listen_score}</span>Writing</Col>
	          			</Row>
	          		</div>
	          		<div className="cardFooter">
	          			<Row>
	          				<Col><a href="javascript:void()" onClick={this.targetToggle}>Set Target <i className="fa fa-angle-right" aria-hidden="true"></i></a></Col>
	          			</Row>
	          		</div>
          		</div>
          	</Col>*/}
            <Col md="7" sm="7">
              <h3 className="cardTitle">Target</h3>
              <div className="userInfoCard purple">
                <div className="cardHeader">
                  <div className="leftContent">Exam Date: {this.state.exam_date}</div>
                  <div className="rightContent"><span>{isNaN(this.state.diff_days)?"-":(this.state.diff_days >0)?this.state.diff_days:0} Days left</span></div>
                </div>
                <div className="cardBody">
                  <Row>
                    <Col className="userRecord"><span>{this.state.dashboardData && this.state.overall_score}</span>Overall</Col>
                    <Col className="userRecord"><span>{this.state.dashboardData && this.state.listening}</span>Listening</Col>
                    <Col className="userRecord"><span>{this.state.dashboardData && this.state.reading}</span>Reading</Col>
                    <Col className="userRecord"><span>{this.state.dashboardData && this.state.speaking}</span>Speaking</Col>
                    <Col className="userRecord"><span>{this.state.dashboardData && this.state.writing}</span>Writing</Col>
                  </Row>
                </div>
                <div className="cardFooter">
                  <Row>
                    <Col><a href="javascript:void()" onClick={this.targetToggle}>Set Target <i className="fa fa-angle-right" aria-hidden="true"></i></a></Col>
                  </Row>
                </div>
              </div>
            </Col>
		  </Row>
		  <div className="testAttempts mt-5">
		  	<div className="row">
		  		<div className="col">
		  			<div className="testRecord speaking">
		  				<h4>Speaking</h4>
		  				<span className="doneQues">Done {this.state.dashboardData && this.state.dashboardData.user_attempted_questions.speaking_questions} questions</span>
		  				<span className="TotalQues">Total {this.state.dashboardData && this.state.dashboardData.speak_t} questions</span>
              <Link to="/admin/progress/1?mock=0">View Analysis</Link>
		  			</div>
		  		</div>
		  		<div className="col">
		  			<div className="testRecord writing">
		  				<h4>Writing</h4>
		  				<span className="doneQues">Done {this.state.dashboardData && this.state.dashboardData.user_attempted_questions.writing_questions} questions</span>
		  				<span className="TotalQues">Total {this.state.dashboardData && this.state.dashboardData.write_t} questions</span>
		  			 <Link to="/admin/progress/2?mock=0">View Analysis</Link>
            </div>
		  		</div>
		  		<div className="col">
		  			<div className="testRecord reading">
		  				<h4>Reading</h4>
		  				<span className="doneQues">Done {this.state.dashboardData && this.state.dashboardData.user_attempted_questions.reading_questions} questions</span>
		  				<span className="TotalQues">Total {this.state.dashboardData && this.state.dashboardData.read_t} questions</span>
		  			   <Link to="/admin/progress/3?mock=0">View Analysis</Link>
            </div>
		  		</div>
		  		<div className="col">
		  			<div className="testRecord listening">
		  				<h4>Listening</h4>
		  				<span className="doneQues">Done {this.state.dashboardData && this.state.dashboardData.user_attempted_questions.listening_questions} questions</span>
		  				<span className="TotalQues">Total {this.state.dashboardData && this.state.dashboardData.listen_t} questions</span>
		  			   <Link to="/admin/progress/4?mock=0">View Analysis</Link>
            </div>
		  		</div>
		  	</div>
		  </div>
		  {this.state.isMainSite && <div className="recentVideos mt-5">
              <div>
            <Nav className="nav-pills-primary nav-pills-icons"
                          pills
                          role="tablist" tabs>
              <NavItem>
                <NavLink1
                  className={(this.state.activeTab === 1)?'active':''}
                  onClick={() => { this.toggle(1); }}
                >
                  Speaking
                </NavLink1>
              </NavItem>
              <NavItem>
                <NavLink1
                  className={(this.state.activeTab === 2)?'active':''}
                  onClick={() => { this.toggle(2); }}
                >
                  Writing
                </NavLink1>
              </NavItem>
              <NavItem>
                <NavLink1
                  className={(this.state.activeTab === 3)?'active':''}
                  onClick={() => { this.toggle(3); }}
                >
                  Reading
                </NavLink1>
              </NavItem>
              <NavItem>
                <NavLink1
                  className={(this.state.activeTab === 4)?'active':''}
                  onClick={() => { this.toggle(4); }}
                >
                  Listening
                </NavLink1>
              </NavItem>
              <NavItem>
                <NavLink1
                  className={(this.state.activeTab === 5)?'active':''}
                  onClick={() => { this.toggle(5); }}
                >
                  Other
                </NavLink1>
              </NavItem>
              
            </Nav>
          </div>
              {this.state.activeTab === 1 && <h3>Speaking</h3>}
              {this.state.activeTab === 2 && <h3>Writing</h3>}
              {this.state.activeTab === 3 && <h3>Reading</h3>}
              {this.state.activeTab === 4 && <h3>Listening</h3>}
              {this.state.activeTab === 5 && <h3>Other</h3>}
              <div className="allVideos row">
                
                
                {this.state.dashboardData && this.state.dashboardData.video.map((val,key)=>{
                    if (val.type == (this.state.activeTab+1)) {
                      return <div className="videoOuter col-sm-4" key={key}>
                        <h5>{val.title}</h5>
                        <iframe src={val.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>;
                    }
                  
                  
                })}
                
                
              </div>
            </div>}
        </div>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  		<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
