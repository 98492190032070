import React,{Component} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,Button,NavLink as NavLink1,
  TabContent, TabPane, Nav, NavItem,CardText
} from "reactstrap";
import API from "api.js";
import { NavLink } from "react-router-dom";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";

class MockResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      arr:{1 : [1,2,3,4,5],2 :[6,7],3 :[8,9,10,11,12],4: [13,14,15,16,17,18,19,20]},
      group:[],
      activeTab:1,
      f:true
    };
  }
   componentDidMount() {
    API({
        method: 'GET',
        url: 'mock/resultDetail/'+this.props.match.params.id
      }).then(data => {
        let group = data.data.data.question.reduce((r, a) => {
           r[a.type] = [...r[a.type] || [], a];
           return r;
          }, {});
        this.setState({group:group,'data':data.data.data.question});
    }).catch(()=>{
      
    });

  }

  getQuestionType = (type) =>{
    let title = "";
    switch(type) {
      case 1:
        title = "Read Aloud";
        break;
      case 2:
        title = "Repeat Sentence"
        break;
      case 3:
        title = "Describe Image";
        break;
      case 4:
        title = "Re-tell Lecture";
        break;
      case 5:
        title = "Answer Short Questions";
        break;
      case 6:
        title = "Summarize written text";
        
        break;
      case 7:
        title = "Write Essay";
        
        break;
      case 8:
        title = "Multiple Type, Single Answer ";
        break;
      case 9:
        title = "Multiple Type, Double Answer ";
        break;
      case 10:
        title = "Reorder Paragraph ";
        break;
      case 11:
        title = "Reading Fill in the Blanks ";
        break;
      case 12:
        title = "Fill in the Blanks Reading & Writing ";
        break;
      case 13:
        title = "Summarize Spoken text ";
        break;
      case 14:
        title = "MCQ Single answer ";
        break;
      case 15:
        title = "MCQ multiple answer ";
        break;
      case 16:
        title = "Listening Fill In the Blanks ";
        break;
      case 17:
        title = "Highlight correct summary ";
        break;
      case 18:
        title = "Select Missing Word ";
        break;
      case 19:
        title = "Highlight Incorrect word ";
        break;
      case 20:
        title = "Write from Dictation ";
        
        break;
      default:
        // code block
    }
    return title;
  }

  calculateScore = (arr) => {
    let score = 0;
    let from = 0;
    arr.map((v)=>{
        v.score.map((val)=>{
          score += val.score;
          from += val.from;
        });
    });
    return `${(score > 0)?score.toFixed(1):score} out of ${from}`;
  }

  getTime = (arr) => {
    let time = 0;
    arr.map((v)=>{
        time += v.q_time;
    });
    return (time > 59)?`${Math.floor(time/60)} minutes ${Math.floor(time%60)} sec`:`${time} sec`;
  }

  getUnattempted = (arr) => {
    let c = 0;
    arr.map((v)=>{
      if (v.not_attempted == 0) {
          c += 1;
      }
        
    });
    return c;
  }

  toggleCorrectSection = () => {
    let tab = 1;
    if(Object.keys(this.state.group).filter(x => [1,2,3,4,5].includes(parseInt(x))).length > 0){
        tab = 1; 
    }else if(Object.keys(this.state.group).filter(x => [6,7].includes(parseInt(x))).length){
        tab = 2;
    }else if(Object.keys(this.state.group).filter(x => [8,9,10,11,12].includes(parseInt(x))).length){
      tab = 3;
    }else{
      tab = 4;
    }
    this.setState({activeTab:tab,f:false});
  }

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab});
    }
  }
 
  render() {
    if (this.state.data.length > 0 && this.state.f) {
      this.toggleCorrectSection();  
    }
    
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Mock Test Analysis</CardTitle>
                </CardHeader>
                <CardBody>
                  <div>
      <Nav className="nav-pills-primary nav-pills-icons justify-content-center"
                    pills
                    role="tablist" tabs>
        {Object.keys(this.state.group).filter(x => [1,2,3,4,5].includes(parseInt(x))).length  !== 0  && <NavItem>
                  <NavLink1
                    className={(this.state.activeTab === 1)?'active':''}
                    onClick={() => { this.toggle(1); }}
                  >
                    Speaking
                  </NavLink1>
                </NavItem>}
                {Object.keys(this.state.group).filter(x => [6,7].includes(parseInt(x))).length  !== 0  && <NavItem>
          <NavLink1
            className={(this.state.activeTab === 2)?'active':''}
            onClick={() => { this.toggle(2); }}
          >
            Writting
          </NavLink1>
        </NavItem>}
        {Object.keys(this.state.group).filter(x => [8,9,10,11,12].includes(parseInt(x))).length !== 0  && <NavItem>
          <NavLink1
            className={(this.state.activeTab === 3)?'active':''}
            onClick={() => { this.toggle(3); }}
          >
            Reading
          </NavLink1>
        </NavItem>}
        {Object.keys(this.state.group).filter(x => [13,14,15,16,17,18,19,20].includes(parseInt(x))).length  !== 0  && <NavItem >
          <NavLink1
            className={(this.state.activeTab === 4)?'active no-border':'no-border'}
            onClick={() => { this.toggle(4); }}
          >
            Listening
          </NavLink1>
        </NavItem>}
      </Nav>
    </div>
                  <Table >
                    <thead className="text-primary">
                      <tr>
                        
                              <th className="">
                                Question Type
                              </th>
                              <th className="">
                                Time
                              </th>
                              <th className="">
                                Question Count
                              </th>
                              <th className="">
                                Question Attempted
                              </th>
                              <th className="">
                                Score
                              </th>
                            
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(this.state.group).map((val, key) => {
                        if (this.state.arr[this.state.activeTab].includes( parseInt(val))) {
                          return (
                          <tr key={key}>
                                  <td className="">
                                    {this.getQuestionType(parseInt(val))}
                                  </td>
                                  <td className="">
                                    {this.getTime(this.state.group[val])}
                                  </td>
                                  <td className="">
                                    {this.state.group[val].length}
                                  </td>
                                  <td className="">
                                    {this.getUnattempted(this.state.group[val])}
                                  </td>
                                  <td className="">
                                      {this.calculateScore(this.state.group[val])}
                                  </td>
                          </tr>
                        );  
                        }
                        
                      })}
                    </tbody>
                  </Table>
                   
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default MockResult;
