import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Home extends React.Component {
    componentDidMount() {
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
    }
 render(){
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>LA Language Academy India Terms & Conditions</title>
        <meta name="description" content="Review LA Language Academy's terms and conditions to understand your rights and responsibilities. Ensure you have all the information before enrolling." />
     </Helmet>
    <Header />
    <div class="innerPage">
    <section class="bannerOuter">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1> TERMS AND CONDITIONS </h1>
                    <span></span><br />
                    <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                </div>
            </div>
        </div>
    </section>
    </div>
    <section id="pte-modules" className="mt-5">
         <div class="container">
            <div class="row text-center">

        <p id="terms">Terms and Conditions agreements act as a legal contract between you (the company) who has the website or mobile app and the user who access your website and mobile app.

Having a Terms and Conditions agreement is completely optional. No laws require you to have one. Not even the super-strict and wide-reaching General Data Protection Regulation (GDPR).

It’s up to you to set the rules and guidelines that the user must agree to. You can think of your Terms and Conditions agreement as the legal agreement where you maintain your rights to exclude users from your app in the event that they abuse your app, where you maintain your legal rights against potential app abusers, and so on.

Terms and Conditions are also known as Terms of Service or Terms of Use. and Conditions are also known as Terms of Service or Terms of Use.erms and Conditions agreements act as a legal contract between you (the company) who has the website or mobile app and the user who access your website and mobile app.

Having a Terms and Conditions agreement is completely optional. No laws require you to have one. Not even the super-strict and wide-reaching General Data Protection Regulation (GDPR).

It’s up to you to set the rules and guidelines that the user must agree to. You can think of your Terms and Conditions agreement as the legal agreement where you maintain your rights to exclude users from your app in the event that they abuse your app, where you maintain your legal rights against potential app abusers, and so on.

Terms and Conditions are also known as Terms of Service or Terms of Use. and Conditions are also known as Terms of Service or Terms of Use.erms and Conditions agreements act as a legal contract between you (the company) who has the website or mobile app and the user who access your website and mobile app.

Having a Terms and Conditions agreement is completely optional. No laws require you to have one. Not even the super-strict and wide-reaching General Data Protection Regulation (GDPR).

It’s up to you to set the rules and guidelines that the user must agree to. You can think of your Terms and Conditions agreement as the legal agreement where you maintain your rights to exclude users from your app in the event that they abuse your app, where you maintain your legal rights against potential app abusers, and so on.

Terms and Conditions are also known as Terms of Service or Terms of Use. and Conditions are also known as Terms of Service or Terms of Use.</p>

        </div>
      </div>
      </section>
    <Footer />
    </React.Fragment>);
    }
}

export default Home;
