import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const Tab = ({ title, price, plan, currency }) => {
  const isGold = title === "GOLD" && plan.is_video !== 0;
  const keyPoints = (
    <ul>
      <li className="mb-2">
        <i className="fa fa-check-circle mr-2"></i>
        <span>Duration 1 Month(s)</span>
      </li>
      <li className="mb-2">
        <i className="fa fa-check-circle mr-2"></i>
        <span>Unlimited Al scoring for the question types.</span>
      </li>
      <li className="mb-2">
        <i className="fa fa-check-circle mr-2"></i>
        <span>Full access to the exam questions.</span>
      </li>
      <li className="mb-2">
        <i className="fa fa-check-circle mr-2"></i>
        <span>Access to latest templates and prediction files.</span>
      </li>
      <li className="mb-2">
        <i className="fa fa-check-circle mr-2"></i>
        <span>Unlimited sectional mock tests with scoring.</span>
      </li>
      <li className="mb-2">
        <i className="fa fa-check-circle mr-2"></i>
        <span>Unlimited full mock tests with scoring.</span>
      </li>
      {isGold && (
        <>
          <li className="mb-2">
            <i className="fa fa-check-circle mr-2"></i>
            <span>Access to reading explanation videos.</span>
          </li>
          <li className="mb-2">
            <i className="fa fa-check-circle mr-2"></i>
            <span>Full access to premium strategy videos.</span>
          </li>
          <li className="mb-2">
            <i className="fa fa-check-circle mr-2"></i>
            <span>Full access to the detailed video course.</span>
          </li>
        </>
      )}
      {!isGold && (
        <>
          <li className="mb-2">
            <span>&nbsp;</span>
          </li>
          <li className="mb-2">
            <span>&nbsp;</span>
          </li>
          <li className="mb-2">
            <span>&nbsp;</span>
          </li>
        </>
      )}
      <li className="mb-2">&nbsp;</li>
    </ul>
  );

  return (
    <div className="col-md-6 darkVersion mb-4 mb-md-0">
      <div className="packageCol">
        <div className="row">
          <div className="col">
            <div className="packageHead d-flex justify-content-between align-items-center w-100">
              <div className="packageHeadLeft">
                <h5 className="m-0">
                  <b>{title}</b>
                </h5>
              </div>
              <div className="packageHeadRight">
                <div className={"priceBox " + (isGold ? "gold" : "silver")}>
                  <h6 className="m-0">
                    {ReactHtmlParser(currency)} {price}
                  </h6>
                  <p className="m-0">
                    <small>Duration 1 month(s)</small>
                    <br />
                    <small>Auto renew</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <div className="customDivider my-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="packageContent">
              {keyPoints}
              <Link
                to={"/admin/sub/payment/form/" + plan.id}
                className="selectPlan"
              >
                Buy Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
