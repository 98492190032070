/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import {store,persistor} from 'store/index';
import API,{ base} from "api.js";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false,
            staffFlag:false,
            isMainSite:false,
            logoIcon:"",
        }
    }

	componentDidMount() {
        const isMainSite = (window.location.hostname.split('.').shift() === "www") ? true : false;
        this.setState({ isMainSite: isMainSite });
        window.scrollTo(0, 0);
        if (!isMainSite) {
			var bodyFormData = new FormData();
			bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
			API({
				method: 'POST',
				url: 'get-logo',
				data: bodyFormData
			}).then(data => {
				if (data.data.data) {
				  this.setState({logoIcon:data.data.data});
				}
			}).catch(()=> {});
		}
	}

    openStaff = () =>{
        this.setState({ staffFlag: !this.state.staffFlag });
    }

	render() {
		return (
      <header className="mainHeader">
        <div className="topLinks">
          <div className="container">
            <div className="row">
              {this.state.isMainSite && (
                <div className="col-sm-12 text-right">
                  <span className="uppercase">
                    EMAIL{" "}
                    <a href="mailto:info@languageacademy.com.au">
                      info@languageacademy.com.au
                    </a>
                  </span>
                  <span className="call uppercase">
                    CALL <a href="tel:7652811911">+91 7652811911</a>
                  </span>
                  <span className="register">
                    <i className="fa fa-user" aria-hidden="true"></i>{" "}
                    <Link to="/auth/register">Register</Link>
                  </span>
                  <span
                    onClick={(e) => {
                      this.openStaff();
                    }}
                    className={
                      this.state.staffFlag
                        ? "staff staffMenu open"
                        : "staff staffMenu"
                    }
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>{" "}
                    <a href="javascript:;">
                      Staff <samp className="caret"></samp>
                    </a>
                    <ul className="stafDropdown">
                      <li>
                        <a href="http://crm3.languageacademy.com.au/">
                          Jalandhar
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="logoNav">
          <div className="container">
            <div className="row">
              {this.state.isMainSite && (
                <div className="col-sm-3 logo">
                  <Link to="/">
                    <img src={base + "/images/logol.png"} alt="logo-dark" />
                  </Link>
                </div>
              )}
              {!this.state.isMainSite && (
                <div className="col-sm-12 logo">
                  <Link to="/auth/login">
                    <img src={base + this.state.logoIcon} alt="logo-dark" />
                  </Link>
                </div>
              )}
              <div className="col-sm-9 navigation text-right">
                <span className="mobileNav">
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </span>
                <div id="navigation">
                  <span className="close-menu">
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </span>
                  {this.state.isMainSite && (
                    <ul id="mainNav">
                      <li>
                        <a href="javascript:void(0);">
                          Courses{" "}
                          <i className="fa fa-sort-desc" aria-hidden="true"></i>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a href="javascript:void(0);">
                              PTE{" "}
                              <i
                                className="fa fa-caret-right"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <ul className="subMenu">
                              <li>
                                <Link to="/pte-core">
                                  PTE Core
                                </Link>
                                <Link to="/pte-online-coaching">
                                  PTE Online Coaching
                                </Link>
                              </li>

                              <li>
                                <Link to="/pte-coaching-classes-parramatta">
                                  PTE Coaching Parramatta
                                </Link>
                              </li>
                              <li>
                                <Link to="/pte-coaching-sydney">
                                  PTE Coaching Sydney
                                </Link>
                              </li>
                              <li>
                                <Link to="/pte-coaching-classes-jalandhar">
                                  PTE Coaching Jalandhar
                                </Link>
                              </li>
                              <li>
                                <Link to="/pte-coaching-classes-gurdaspur">
                                  PTE Coaching Gurdaspur
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              NAATI CCL{" "}
                              <i
                                className="fa fa-caret-right"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <ul className="subMenu">
                              <li>
                                <Link to="/naati-ccl-online-coaching">
                                  Naati CCL Online Coaching
                                </Link>
                              </li>
                              <li>
                                <Link to="/naati-coaching-classes-parramatta">
                                  Naati CCL Coaching Parramatta
                                </Link>
                              </li>
                              <li>
                                <Link to="/naati-ccl-coaching-sydney">
                                  Naati CCL Coaching Sydney
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/ielts-coaching-jalandhar">IELTs </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          Resources{" "}
                          <i className="fa fa-sort-desc" aria-hidden="true"></i>
                        </a>
                        <ul className="subMenu">
                          <li>
                            {!store.getState().token && (
                              <Link to="/auth/login">PTE</Link>
                            )}
                            {store.getState().token && (
                              <Link to="/admin/test-material">PTE</Link>
                            )}
                          </li>
                        </ul>
                      </li>
                      {/*<li>
                        <Link to="/video-courses">Video Course</Link>
                      </li>*/}
                      <li>
                        <Link to="/pte-resource-page">PTE Resource</Link>
                      </li>
                      <li>
                        <Link to="/mock-test">FREE MOCK TEST</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                      <li className="login">
                        <a href={`${process.env.REACT_APP_LIVE_URL}/auth/login`}>
                          <i className="fa fa-lock" aria-hidden="true"></i>
                          <span>Login</span>
                        </a>
                        {/* {!store.getState().token && (
                        )}
                        {store.getState().token && (
                          <Link to="/admin/dashboard">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                            <span>Portal</span>
                          </Link>
                        )} */}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
	}
}

export default Header;
