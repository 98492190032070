import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import cookie from 'react-cookies'
import { connect } from 'react-redux';
import "components/custom.css";
import API from "api.js";
import { Redirect, NavLink,Link } from "react-router-dom";
import { setToken, setUser, setLogo, setSub} from 'action/index.js';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub)),
      setLogo: (sub) => dispatch(setLogo(sub))
   };
};
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      loginLock:false,
      logoIcon:"/images/logol.png",
      isMainSite: (window.location.hostname.split('.').shift() === "www")?true:false
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    window.location.href = `${process.env.REACT_APP_LIVE_URL}/auth/login`;
    // ReactGA.initialize('UA-157984080-1');
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('3847665161963731');
    ReactPixel.pageView();
    document.body.classList.toggle("login-page");
    this.props.setToken(null);

  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  postLogIn = async() => {
    if (this.state.loginLock) {
      return ;
    }
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }
    this.setState({error_bag:null,loginLock:true});

    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/login',
        data: bodyFormData
      })
    }catch(error) {


    };

    if (res.status === 200) {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.props.setToken(res.data.access_token);
      this.props.setUser(res.data.user);
      this.props.setLogo(res.data.image);
      let sub  = (res.data.user.active_subscription.length === 0)?false:true;
      this.props.setSub(sub);
      if (res.status === 200) {
        window.location.href  = "/admin/dashboard";
        //this.setState({login:true,loginLock:false});
      }

    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';

      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str,loginLock:false});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Wrong credentials.",loginLock:false});
    }

  }

  submitForm = (e) => {
    e.preventDefault();
    this.postLogIn();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };
  render() {
    const {token,setToken} = this.props;
    if (this.state.login) {
        //return (<Redirect to="/admin/dashboard" />);
    }
    return (
      <div className="innerPage">
      <div className="login-page">
      <Helmet htmlAttributes>
        <title>Access Your Courses with  LA  Academy India Login </title>
        <meta name="twitter:title" content="Login - Language Academy" />
      <meta property="og:title" content="Login - Language Academy" />
      <meta name="description" content="Access your LA Language Academy India account to manage your courses and materials. Log in to continue your learning journey with ease." />

        <meta name="twitter:description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />
        <meta property="og:description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />
    </Helmet>
      <section className="bannerOuter">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h1>Login</h1>

      </div>
    </div>
  </div>
</section>
<section className="contentOuter">
  <form onSubmit={this.submitForm}>
  <div className="container">
    <div className="row">
      <div className="col-sm-7 loginForm">
        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
          <div className="fieldOuter">
            <Input
                placeholder="Email"
                type="text"
                name="email"
                value={this.state.email}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('Email', this.state.email, 'required|email')}
          </div>
          <div className="fieldOuter">
            <Input
                placeholder="Password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
              {this.validator.message('Password', this.state.password, 'required')}
          </div>
          <div className="rememberForgot">
            <div className="rememberMe">
              <input type="checkbox" name="" />
              <span>Remember me</span>
            </div>
            <div className="forgotPwd">
              <NavLink className="mt-5" to='/auth/forgot'><span>Forgot your Password?</span></NavLink>
            </div>
          </div>
          <div className="fieldOuter text-right">
            <Button
              className="themeBtn"
              onClick={(e) => {this.postLogIn()}}
            >
              Login
            </Button>
          </div>
      </div>
      <div className="col-sm-5 registerLink text-center">
       {this.state.isMainSite && <React.Fragment> <h3>New User?</h3>
               <p>Register now to obtain your<br /> Free Mock Test Material</p>
               <Link to="/auth/register" className="themeBtn blueBtn">Register</Link> </React.Fragment>}
      </div>
    </div>
  </div>
  <input  type="submit" style={{display:"none"}} />
  </form>
</section>
       {/*<Container>
          <Row >
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => { this.changeInput(e)} }
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={(e) => { this.changeInput(e)} }
                        autoComplete="off"
                      />
                    </InputGroup>
                    <br />
                    <FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultChecked
                            defaultValue=""
                            type="checkbox"
                          />
                          <span className="form-check-sign" />
                          Subscribe to newsletter
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>

                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      onClick={(e) => {this.postLogIn(e)}}
                    >
                      Get Started
                    </Button>
                    <NavLink className="mt-5" to='/auth/forgot'>Forgot Password?</NavLink>
                  </CardFooter>

                </Card>
              </Form>
            </Col>
          </Row>
        </Container>*/}
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`
          }}
        />
      </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
