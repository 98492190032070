import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Refund extends React.Component {
    componentDidMount() {
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
    }
 render(){
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>LA Language Academy India Easy Refund Policies</title>
        <meta name="description" content="Learn about LA Language Academy's refund policies for all courses and services. Understand our transparent and hassle-free refund process." />
     </Helmet>
    <Header />
    <div class="innerPage">
    <section class="bannerOuter">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1> REFUND POLICIES </h1>
                    <span></span><br />
                    <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                </div>
            </div>
        </div>
    </section>
    </div>
    <section id="pte-modules" className="mt-5">
         <div class="container">
            <div class="row text-center">

        <p id="terms">
            We don't offer any refund in any given circumstances. Nevertheless, a refund is possible is in case you are charged extra due to a technical problem. The refund request should be initiated within 24 hours by sending an email with all the details on info@languageacademy.com.au
        </p>

        </div>
      </div>
      </section>
    <Footer />
    </React.Fragment>);
    }
}

export default Refund;
