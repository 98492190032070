import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API from "../api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Redirect} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
class PayOnline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pay_amount:'',
            email:"",
            currency:"",
            error:[],
            paymentForm:false,
            submit:false,
            disableSubmit:false,
            flag:false,
            lockSubmit:false,
            cvc: '',
            message: '',
            expYear: '',
            expMonth: '',
            cardNumber: '',
            formProcess: false,
            paymentStatus:false,
            plan:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        window.location.href = `${process.env.REACT_APP_LIVE_URL}${window.location.pathname}`;
        // ReactGA.initialize('UA-157984080-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('3847665161963731');
        ReactPixel.pageView();
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]:value});
    }
    handleCardChange = (evt) => {
        this.setState({
          [evt.target.name] : evt.target.value
        });
    }
    submitForm = () =>{
        if (this.validator.allValid() && !this.state.lockSubmit) {
            this.setState({lockSubmit:true});
            let json = {
                "pay_amount": this.state.pay_amount,
                "email": this.state.email,
                "currency": this.state.currency,
            }
            console.log(this.state);
            API({
              method: 'POST',
              url: "post/check-user-active",
              data:json,
            }).then(data => {
                this.setState({submit:true,paymentForm:true,lockSubmit:false,disableSubmit:true,flag:true,message:'User Found. Please Enter Card Details!'});
                store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.response.message}));
                this.loadStripe();
                console.log('then');
            }).catch(()=>{
                this.setState({lockSubmit:false,paymentForm:false,disableSubmit:false,message:'User Not Found with this Email!'});
                console.log('catch');
            });
        } else {
            console.log('else');
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    loadStripe = () => {
        if(!window.document.getElementById('stripe-script')) {
            var s = window.document.createElement("script");
            s.id = "stripe-script";
            s.type = "text/javascript";
            s.src = "https://js.stripe.com/v2/";
            s.onload = () => {
                // window['Stripe'].setPublishableKey('pk_live_JIwxa9FoBUiNl8YZcQClauSQ00aVGdi4OQ');
                window['Stripe'].setPublishableKey('pk_test_f3HFKuah7T4BNhb9cReEXjEY00d143JEoD');
            }
            window.document.body.appendChild(s);
        }
    }
    pay = async (e) => {
        e.preventDefault();
        this.setState({
            formProcess: true
        });
        window.Stripe.card.createToken({
            number: this.state.cardNumber,
            exp_month: this.state.expMonth,
            exp_year: this.state.expYear,
            cvc: this.state.cvc
        },async (status, response) => {
            if (status === 200) {
                this.setState({
                    message: ``,
                    formProcess: false
                });
                var bodyFormData = new FormData();
                // bodyFormData.set('plan', this.props.match.params.id);
                bodyFormData.set('token', response.id);
                let res = null;
                console.log(bodyFormData);
                console.log(this.state);
                console.log(response.id);
                // try{
                //     res = await API({
                //         method: 'POST',
                //         url: 'payouts',
                //         data: bodyFormData,
                //     })
                // }catch(error) {
                //     console.log(error);
                // };
                // if(res){
                //     if (res.status === 200) {
                //         this.notify(1,res.data.message);
                //         this.setState({paymentStatus:true});
                //     }
                // }
            } else {
                this.setState({
                message: response.error.message,
                formProcess: false
                });
            }
        });
      }
 render(){
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Language Academy</title>
        <meta name="twitter:title" content="Language Academy" />
        <meta property="og:title" content="Language Academy" />
     </Helmet>
    <Header />
      <div class="innerPage">
        <section class="bannerOuter">
          <div class="container">
              <div class="row">
                  <div class="col-sm-12 text-center">
                      <h1>Pay Online</h1>
                      <span>Complete Preparation Package To Achieve The Score You Desire</span><br />
                      <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                  </div>
              </div>
          </div>
        </section>
      </div>
        {!this.state.paymentForm && <section class="paysection">
            <div class="container">
              <div class="row">
                <div class="payOnlineForm" id="payOnlineFormId">
                  <h2 class="sectionTitle">Enter Payment Details</h2>
                    <div class="fieldOuter">
                        <input type="number" name="pay_amount" onChange={this.handleChange} value={this.state.pay_amount} placeholder="Pay Amount..." min="0" />
                        {this.validator.message('Pay Amount', this.state.pay_amount, 'required')}
                    </div>
                    <div class="fieldOuter">
                        <input type="email" onChange={this.handleChange} value={this.state.email} name="email" placeholder="Your email..." />
                        {this.validator.message('Email', this.state.email, 'required|email')}
                    </div>
                    <div class="fieldOuter">
                        <select class="form-control currency" name="currency" onChange={(e) => { this.handleChange(e)} } value={this.state.currency}>
                            <option value="">Select Currency</option>
                            <option value="USD">U.S. Dollar ($)</option>
                            <option value="AUD">Australian Dollar (AUD)</option>
                            <option value="CAD">Canadian dollar (CAD)</option>
                            <option value="INR">Indian rupee (INR)</option>
                            <option value="NPR">Nepalese rupee (NPR)</option>
                            <option value="GBP">British Pound (£)</option>
                        </select>
                        {this.validator.message('Currency', this.state.currency, 'required')}
                    </div>
                    <div class="fieldOuter text-right">
                        {!this.state.lockSubmit && <button type="submit" name="" onClick={this.submitForm} class="themeBtn" >Submit</button>}
                        {this.state.lockSubmit && <button type="submit" name="" class="themeBtn" >Checking User...</button>}
                    </div>
                </div>
              </div>
            </div>
            <p className="container text-center text-danger"><b><i>{this.state.message}</i></b></p>
        </section>}
        {this.state.paymentForm && <div className="col-xs-12 col-md-6 mr-auto ml-auto pt-5 subPaymentPage">
                <section class="text-center">
                    <table className="table table-bordered data-table no-footer">
                        <tbody>
                            <tr>
                                <td>Email</td>
                                <td>{this.state.email}</td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td>{this.state.pay_amount}</td>
                            </tr>
                            <tr>
                                <td>Currency</td>
                                <td>{this.state.currency}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div className="panel panel-default">
                    <form onSubmit={this.pay}>
                      <div className="panel-body mt-5">

                          <p><b>{this.state.message}</b></p>
                          <div className="row">

                              <div className="col-xs-12 col-md-12">
                                  <div className="form-group">
                                      <label>CARD NUMBER</label>
                                      <div className="input-group">
                                          <input type="text" className="form-control" placeholder="Valid Card Number" name="cardNumber" maxLength="18" onChange={this.handleCardChange} />
                                          <span className="input-group-addon"><span className="fa fa-credit-card"></span></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-xs-7 col-md-7">
                                  <div className="form-group">
                                      <label><span className="hidden-xs">EXPIRATION</span> Date</label>
                                      <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-group">
                                                  <select name="expMonth" className="form-control"  onChange={this.handleCardChange}>
                                                      <option value="">Select Month</option>
                                                      <option value="1">01</option>
                                                      <option value="2">02</option>
                                                      <option value="3">03</option>
                                                      <option value="4">04</option>
                                                      <option value="5">05</option>
                                                      <option value="6">06</option>
                                                      <option value="7">07</option>
                                                      <option value="8">08</option>
                                                      <option value="9">09</option>
                                                      <option value="10">10</option>
                                                      <option value="11">11</option>
                                                      <option value="12">12</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div className="col-md-6 pull-right">
                                              <div className="form-group">
                                                  <select name="expYear" className="form-control" onChange={this.handleCardChange}>
                                                      <option value="">Select Year</option>
                                                      <option value={new Date().getYear() - 100}>{1900 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 99}>{1901 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 98}>{1902 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 97}>{1903 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 96}>{1904 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 95}>{1905 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 94}>{1906 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 93}>{1907 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 92}>{1908 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 91}>{1909 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 90}>{1910 + new Date().getYear()}</option>
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xs-5 col-md-5 pull-right">
                                  <div className="form-group">
                                      <label>CVV CODE</label>
                                      <input type="text" name="cvv" className="form-control" placeholder="CVC" maxLength="4" onChange={this.handleCardChange} />
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="panel-footer">
                          <div className="row">
                              <div className="col-xs-12 col-md-12">
                                  { (this.state.formProcess)? (
                                      <span className="btn btn-warning btn-lg btn-block">Please wait...</span>
                                    ) : (
                                      <button className="btn btn-warning btn-lg btn-block">Process payment</button>
                                    )}
                              </div>
                          </div>
                      </div>
                  </form>
                  <p className="mt-2"></p>
                </div>
            </div>}
    <Footer />
    </React.Fragment>);
    }
}

export default PayOnline;
