import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Alert,
  Col
} from "reactstrap";
import API from "api.js";
import { NavLink } from "react-router-dom";
import {store,persistor} from 'store/index';
import { Helmet } from "react-helmet";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paidMember:true,
      loadmore:false
    };
  }
   componentDidMount() {
    
    this.loadTemplate();
  }
 
 loadTemplate = () =>{
  API({
      method: 'GET',
      url: 'template/listNew?skip='+this.state.data.length+'&type=1'
      }).then(data => {
        let loadmore = true;
        if (data.data.length < 10) {
          loadmore = false;
        }
        this.setState({'data':[...this.state.data,...data.data],loadmore:loadmore});
    }).catch(()=>{
        
    });
 }

  render() {
    return (
      <>
      <Helmet htmlAttributes>
        <title>Top PTE Training Institute</title>
        </Helmet>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
               {!this.state.paidMember && <Alert color="success">Please upgrade your plan to access secret templates.</Alert>}
                <CardHeader>
                  <CardTitle tag="h4">Help</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table >
                    <thead className="text-primary">
                      <tr>
                        
                              <th className="">
                                Question
                              </th>
                              <th className="">
                                Resolution
                              </th>
                            
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((val, key) => {
                        return (
                          <tr key={key}>
                                  <td className="">
                                    {val.title}
                                  </td>
                                  <td className="">
                                     <NavLink
                                        to={'/admin/viewpdf/'+val.id}
                                        className="nav-link"
                                        activeClassName="active"
                                      >
                                        <i className="" />
                                        <p>View</p>
                                      </NavLink>
                                    
                                  </td>
                                
                          </tr>
                        );
                      })}
                    {this.state.loadmore && <button className="btn" onClick={(e)=>{this.loadTemplate();}}>Load more</button>}
                    </tbody>
                    
                  </Table>
                   
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default Template;
